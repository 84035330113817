import React from 'react';
import {
    GroupContainer,
    HeaderImage,
    New,
    GroupDataContainer,
    GroupName,
    GroupCreator,
    GroupParticipants,
    CrownIcon
} from './styles';

const GroupCard = ({name, participants, crown, media, isTop=false, isNew=false }) => {
    return (
        <GroupContainer isTop={isTop} >
            <HeaderImage image={media}>{isNew && <New />}</HeaderImage>
            <GroupDataContainer isTop={isTop}>
                <GroupName isTop={isTop}>{name}</GroupName>
                <GroupCreator>
                    <CrownIcon/>
                    {' '}
                    {crown}
                </GroupCreator>
                <GroupParticipants isTop={isTop}>
                    {
                        `${participants} ${participants > 1 ?
                        'Participantes' : 'Participante' }`
                    }
                </GroupParticipants>
            </GroupDataContainer>
        </GroupContainer>
    )
}

export default GroupCard;
