import React, { useContext, useState, useEffect } from 'react';
import { ButtonJF, Input, Modal } from 'components';
import { useForm } from "react-hook-form";
import { UserContext } from 'context/UserContext';
import { useHistory } from 'react-router-dom';

import {
    VerticalListContainer,
    HorizontalButtonContainer,
    Separator,
    TitleProfile,
    ProfileContainer,
    FormContainer,
    InputContainer,
    WhenNoHeader,
    ErrorParagraph
} from './styles';

import { ReactComponent as User } from 'assets/user.svg';
import { ReactComponent as Calendar } from 'assets/calendar.svg';  
import { ReactComponent as Arrow } from 'assets/arrow-down.svg';

import { useUpdateInfo } from 'services/Private/useUserService'; 
import * as Routes from 'constants/Routes';
import * as Selects from 'constants/SelectCatalogue';


const Profile = () => {
    const history = useHistory();
    const { control, handleSubmit, formState: { errors } } = useForm();   
    const { user } = useContext(UserContext);
    const [ modalObject, setModalObject ] = useState({"right":true, "text": "", "open":false});
    const [ needState, setNeedState ] = useState(false);
    const { loading, handleUpdateInfo } = useUpdateInfo();

    const handleClose = () => {
        setModalObject(object => {
            return {
                ...object,
                open: false
            }
        });
    };
    
    const onSubmit = async formData => {
        await handleUpdateInfo({formData}).then(response => {
            if (response?.status === 200) {
                setModalObject(object => {
                    return {
                        ...object,
                        open: true,
                        right: true,
                        text: "¡Recibimos la actualización de tu perfil!"
                    }
                });
            } else {
                setModalObject(object => {
                    return {
                        ...object,
                        open: true,
                        right: false,
                        text: "Algo salió mal, intenta de nuevo."
                    }
                });
            }
        }).catch(err => console.log(err));
    };

    const handleClickPassword = () => {
        history.push(Routes.PASSWORD);
    }

    const handleCountryChange = (event: object) => {
        if(event.target.value === "96e08eb9-8ea2-4639-9301-2eae93fe5370"){
            setNeedState(true)
        }else{
            setNeedState(false)
        }
    }

    useEffect(() => {
        if(user)
            handleCountryChange({"target":{"value":user?.extraInfo?.countryUuid}})
    },[user])

    

    return (
    	<>
            {(user) &&
        		<ProfileContainer>
                    <WhenNoHeader>
                        <Separator height={10} />
                        <TitleProfile>Perfil</TitleProfile>
                        <Separator height={10} />
                    </WhenNoHeader>
    		        <VerticalListContainer>
                        <FormContainer onSubmit={handleSubmit(onSubmit)}>
                            <InputContainer>
                                <Input
                                    name='firstName'
                                    placeholder='Nombre'
                                    type='text'
                                    control={control}
                                    required
                                    Icon={User}
                                    value={user?.firstName}
                                />
                                {(undefined !== errors.firstName && errors.firstName.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            <InputContainer>
                                <Input
                                    name='lastName'
                                    placeholder='Apellido'
                                    type='text'
                                    control={control}
                                    required
                                    Icon={User}
                                    value={user?.lastName}
                                />
                                {(undefined !== errors.lastName && errors.lastName.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            <InputContainer>
                                <Input 
                                    name='dateBirth'
                                    placeholder='Fecha de nacimiento'
                                    type='date'
                                    control={control}   
                                    required                  
                                    Icon={Calendar}
                                    value={user?.dateBirth}
                                />
                                {(undefined !== errors.dateBirth && errors.dateBirth.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            <InputContainer>
                                <Input 
                                    name='gender'
                                    placeholder='Sexo'
                                    type='select'
                                    control={control}             
                                    options={Selects.Sex}   
                                    value={user?.gender}                
                                />
                            </InputContainer>
                            <InputContainer>
                                <Input 
                                    name='countryUuid'
                                    placeholder='País'
                                    type='select'
                                    control={control}             
                                    options={Selects.Countries}
                                    value={user?.extraInfo?.countryUuid} 
                                    onChange={handleCountryChange}
                                    required                 
                                />
                                {(undefined !== errors.countryUuid && errors.countryUuid.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            { (needState) &&
                                <InputContainer>
                                    <Input 
                                        name='stateUuid'
                                        placeholder='Estado'
                                        type='select'
                                        control={control}             
                                        options={Selects.States}
                                        value={user?.extraInfo?.stateUuid} 
                                        required                 
                                    />
                                    {(undefined !== errors.stateUuid && errors.stateUuid.type === 'required') && 
                                        <ErrorParagraph>Campo requerido</ErrorParagraph>
                                    }
                                </InputContainer>
                            }
                            <InputContainer>
                                <Input 
                                    name='cellphoneNumber'
                                    placeholder='Teléfono'
                                    type='phone'
                                    control={control}   
                                    required                  
                                    Icon={Arrow}
                                    value={user?.extraInfo?.cellphoneNumber} 
                                />
                                {(undefined !== errors.cellphoneNumber && errors.cellphoneNumber.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            { (!loading) && 
                                <HorizontalButtonContainer>
                                    <ButtonJF type='button' text='Cambiar Contraseña' onClick={ handleClickPassword } isGray/>
                                    <ButtonJF type='submit' text='Actualizar datos'/>
                                </HorizontalButtonContainer>
                            }
                        </FormContainer>
    		        </VerticalListContainer>
                    <Modal open={modalObject.open} right={modalObject.right} text={modalObject.text} handleClose={handleClose} />
    	        </ProfileContainer>
            }
	    </>
    )
}

export default Profile
