import styled from 'styled-components';

export const CompetitionBack = styled.div `
    width: 100%;
    height: 660px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: ${props => props.bgColor ? props.bgColor : 'gray'};
`;

export const BackBeisMx = styled(CompetitionBack) `
    background-color: red;
`;

export const BackChampions = styled(CompetitionBack) `
    background-color: blue;
`;

export const BackNFL = styled(CompetitionBack) `
    background-color: gray;
`;

export const BackLigaMx = styled(CompetitionBack) `
    background-color: green;
`;

export const CompetitionName = styled.div `
    max-width: 400px;
    width:100%;
    height: 400px;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    margin: auto;
    img {
        width: 100%;
        height: auto;
        align-items: center;
    }
`;