import styled from 'styled-components';

export const LegalsContainer = styled.div`
    position: relative;
`;

export const VerticalListContainer = styled.div`
    width: 100%;
    padding: 0px 16px;
    display: grid;
    flex-direction: column;
    grid-gap: 16px;
    align-items: center;
    text-align:center;
    margin-bottom: 16px;
`;

export const TitleLegals = styled.div`
    width: 100%;
    height: 36px;
    border-left: 3px solid #3F9E34;
    padding: 0px 20px;
    color: #3F9E34;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
`;

export const Separator = styled.div`
    width: 100%;
    height: ${props => `${props.height}px`};
`;

export const WhenNoHeader = styled.div`
    @media (min-width: 769px)  {
        display: none;
    }
`;