import styled from 'styled-components';
import yesDesk from '../../assets/yes-1.png';
import noDesk from '../../assets/no-1.png';

export const ContentModal = styled.div `
    width: 400px;
    padding: 50px;
    background: #fff;
    border-radius: 12px;
    position: absolute;
    border: 2px solid #000;
    padding: 16px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 420px)  {
        width: 90%;
        margin:5% 0;
    }
`;

export const Text = styled.div `
    width: 100%;
    font-size: 24px;
    margin: 0px auto 25px auto;
    text-align: center;
    font-weight: 600;
`;

export const Icon = styled('div').withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          (!['isRight'].includes(prop) && defaultValidatorFn(prop)),
    })`
    width: 80px;
    height: 80px;
    background-image: url(${props => props.isRight ? yesDesk:noDesk});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    border: none;
    margin: 0px auto 25px auto;
`;

export const Sponsor = styled.div `
    width: 80%;
    margin: 0px auto 10px auto;

    img {
        width:100%;
    }
`;