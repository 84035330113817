import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card , ButtonJF, Input } from 'components';
import { 
    FormContainer, 
    LoginLink, 
    CountParagraph, 
    UpperContainer,
    BottomContainer,
    SuccessContainer,
    SentEmailParagraph,
    InfoParagraph
} from './styles';
import { useForm } from "react-hook-form";

import { useRecoverPassword } from 'services/Public/usePublicService';

import { ReactComponent as SuccessIcon } from 'assets/success.svg';
import { ReactComponent as Email } from 'assets/arroba.svg';

const RecoverCard = ({ handleLogin, closeDrawers }) => {
    const { control, handleSubmit } = useForm();

    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ emailReset, setEmailReset ] = useState();
    const { handleRecoverPassword } = useRecoverPassword();
    
    const onSubmit = async formData => {

        await handleRecoverPassword(formData.email).then(response => {
            if(response?.data?.status === 200){
                setShowSuccess(true);
                setEmailReset(formData.email);
            }
        }).catch(err => console.log(err));
    };

    return (
        <>
            { showSuccess ?
                <Card>
                    <SuccessContainer>
                        <SuccessIcon />
                        <SentEmailParagraph>¡Correo enviado!</SentEmailParagraph>
                        <InfoParagraph>
                            Te hemos enviado un correo a { emailReset } con las instrucciones para recuperar tu contraseña.
                        </InfoParagraph>
                        <ButtonJF type='button' onClick={ closeDrawers } text='Cerrar'/>
                    </SuccessContainer>
                </Card>
            :
                <Card>
                    <UpperContainer>
                        Ingresa tu correo electrónico y te enviaremos las instrucciones para que puedas recuperartu contraseña
                    </UpperContainer>
                    <FormContainer onSubmit={handleSubmit(onSubmit)}>
                        <Input 
                            where='recover'
                            name='email'
                            placeholder='Correo electrónico'
                            type='email'
                            control={control}
                            Icon={Email}
                        />
                        <ButtonJF type='submit' text='Recuperar mi contraseña'/>
                    </FormContainer>
                    <BottomContainer>
                        <CountParagraph>Ya tienes una cuenta?</CountParagraph>
                        {' '}
                        <LoginLink onClick={ handleLogin } >Inicia sesión</LoginLink>
                    </BottomContainer>
                </Card>
            }
        </>
        
    );
}

RecoverCard.propTypes = {
  handleLogin: PropTypes.func,
  closeDrawers: PropTypes.func,
}

export default RecoverCard;