import React from 'react';
import { LoginCard } from 'components';
import { LoginContainer } from './styles';

const Login = () => {
    return (
        <LoginContainer>
            <LoginCard />
        </LoginContainer>
    )
}

export default Login;
