import PropTypes from 'prop-types';

import {
    HeaderContainer,
    LogoContainer,
    InteractionPanel,
    RegisterLink,
    Button
} from './styles';

import JFLogo from 'assets/logo-jf.svg';

const PublicHeader = ({handleRegister, handleLogin}) => {
    return (

        <HeaderContainer>
            <LogoContainer to="/">
                <img src={JFLogo} alt='juanfutbol' />
            </LogoContainer>
            <InteractionPanel>
                <RegisterLink onClick={handleRegister}>
                    Regístrate
                </RegisterLink>
                <Button onClick={handleLogin}>
                    Iniciar sesión
                </Button>
            </InteractionPanel>
        </HeaderContainer>
        
    );
};

PublicHeader.propTypes = {
  handleRegister: PropTypes.func,
  handleLogin: PropTypes.func,
};

export default PublicHeader;