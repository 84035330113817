import React, { useState, useContext, useRef } from 'react';
import { ButtonJF, Input, Modal } from 'components';
import { useForm } from "react-hook-form";
import { UserContext } from 'context/UserContext';

import {
    VerticalListContainer,
    HorizontalButtonContainer,
    Separator,
    TitleProfile,
    ProfileContainer,
    FormContainer,
    InputContainer,
    WhenNoHeader,
    ErrorParagraph
} from './styles';


import { useChangePassword } from 'services/Private/useUserService';
import { ReactComponent as Eye } from 'assets/eye.svg';

const Profile = () => {
    const { control, reset, handleSubmit, formState: { errors } } = useForm();   
    const { loading, handleChangePassword } = useChangePassword();
    const { user } = useContext(UserContext);
    const [ modalObject, setModalObject ] = useState({"right":true, "text": "", "open":false});

    const oldPassword = useRef(null);
    const password = useRef(null);
    const confirmPassword = useRef(null);

    const handleClose = () => {
        setModalObject(object => {
            return {
                ...object,
                open: false
            }
        });
    };

    const clearForm = () => {
        if(confirmPassword?.current)
            confirmPassword.current.value = null;

        if(password?.current)
            password.current.value = null;

        if(oldPassword?.current)
            oldPassword.current.value = null;

        reset();
    };
    
    const onSubmit = async formData => {
        formData.userUuid = user.uuid;

        await handleChangePassword({formData}).then(response => {
            if (response?.status === 200) {
                setModalObject(object => {
                    return {
                        ...object,
                        open: true,
                        right: true,
                        text: "¡Recibimos el cambio de contraseña!"
                    }
                });

                clearForm();
            } else {
                setModalObject(object => {
                    return {
                        ...object,
                        open: true,
                        right: false,
                        text: "Algo salió mal, intenta de nuevo."
                    }
                });
            }
        }).catch(err => console.log(err));
    };

    return (
        <>
            {(user) &&
        		<ProfileContainer>
                    <WhenNoHeader>
                        <Separator height={10} />
                        <TitleProfile>Contraseña</TitleProfile>
                        <Separator height={10} />
                    </WhenNoHeader>
        	        <VerticalListContainer>
                        <FormContainer onSubmit={handleSubmit(onSubmit)}>
                            <InputContainer>
                                <Input 
                                    name='oldPassword'
                                    placeholder='Contraseña Antigua'
                                    type='password'
                                    control={control}   
                                    required                  
                                    Icon={Eye}
                                    inputRef={oldPassword}
                                />
                                {(undefined !== errors.oldPassword && errors.oldPassword.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            <InputContainer>
                                <Input 
                                    name='password'
                                    placeholder='Contraseña'
                                    type='password'
                                    control={control}   
                                    required                  
                                    Icon={Eye}
                                    inputRef={password}
                                />
                                {(undefined !== errors.password && errors.password.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            <InputContainer>
                                <Input 
                                    name='confirmPassword'
                                    placeholder='Repetir contraseña'
                                    type='password'
                                    control={control}   
                                    required                  
                                    Icon={Eye}   
                                    inputRef={confirmPassword}
                                />
                                {(undefined !== errors.confirmPassword && errors.confirmPassword.type === 'required') && 
                                    <ErrorParagraph>Campo requerido</ErrorParagraph>
                                }
                            </InputContainer>
                            <HorizontalButtonContainer>
                                {!loading &&
                                    <ButtonJF type='submit' text='Cambiar Contraseña'/>
                                }
                            </HorizontalButtonContainer>
                        </FormContainer>
        	        </VerticalListContainer>
                    <Modal open={modalObject.open} right={modalObject.right} text={modalObject.text} handleClose={handleClose} />
                </ProfileContainer>

            }
        </>
    )
}

export default Profile
