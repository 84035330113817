import styled from 'styled-components';

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    text-align: center;
    color: #646761;
    margin-top: 0px;
`;

export const BottomContainer = styled.p`
    text-align: center;
    margin-top: 45px;
`;