import styled from 'styled-components';

import { ReactComponent as Gold } from 'assets/gold-band.svg';
import { ReactComponent as Silver } from 'assets/silver-band.svg';
import { ReactComponent as Bronce } from 'assets/bronce-band.svg';
import { ReactComponent as Green } from 'assets/green-band.svg';

export const TopContainer = styled.div`
    width: 100%;
    height: 100px;
    position: relative;

    background: ${props => props.isMe ? 'linear-gradient(0deg, rgba(63, 158, 52, 0.1), rgba(63, 158, 52, 0.1)), #FFFFFF' : 'linear-gradient(90deg, rgba(224, 225, 223, 0.72) 0%, rgba(224, 225, 223, 0.1728) 100%)' };
    border-radius: 12px;

    @media (max-width: 1025px)  {
        height: 75px;
    }

    @media (max-width: 769px)  {
        height: 50px;
    }
`;

export const TopDataContainer = styled.div`
    position: absolute;
    width: 100%;
    height:100%;
    font-family: 'Montserrat';
    font-style: normal;
    letter-spacing: 0.175714px;
    color: #000000;
    overflow:hidden;

    display: grid;
    grid-template-columns: 100px 70% auto;
    grid-template-rows: 100%;
    grid-template-areas: 'badge name points';
    gap: 0px;

    @media (max-width: 1025px)  {
        grid-template-columns: 75px 70% auto;
    }

    @media (max-width: 769px)  {
        grid-template-columns: 50px 70% auto;
    }
`;

export const TopPoints = styled.div`
    grid-area: points;
    font-size: 3vw;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: normal;
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 5px;

    > .MuiSkeleton-root {
        width:50%;
        line-height: 4vw;
    }

    @media (min-width: 769px)  {
        font-size: 2vw;
    }
`;

export const TopPointsText = styled.span`
    font-size: 2.5vw;

    @media (min-width: 769px)  {
        font-size: 1.5vw;
    }
`;

export const TopName = styled.div`
    grid-area: name;
    font-size: 2.5vw;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: normal;
    display: flex;
    align-items: center;

    > .MuiSkeleton-root {
        width:50%;
        line-height: 4vw;
    }

    @media (min-width: 769px)  {
        font-size: 2vw;
    }
`;

export const TopIconGrid = styled.div`
    grid-area: badge;
    width:80%;
    margin:10%;
    position:relative;

    > .MuiSkeleton-root {
        margin:10%;
        width:80%;
        height:80%;
    }
`;

export const GoldBand = styled(Gold)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-top: -10%;
    margin-bottom:-10%;
    margin-left:10%;
    margin-right:-20%;
`;

export const SilverBand = styled(Silver)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-top: -10%;
    margin-bottom:-10%;
    margin-left:10%;
    margin-right:-20%;
`;

export const BronceBand = styled(Bronce)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-top: -10%;
    margin-bottom:-10%;
    margin-left:10%;
    margin-right:-20%;
`;

export const GreenBand = styled(Green)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-top: -10%;
    margin-bottom:-10%;
    margin-left:10%;
    margin-right:-20%;
`;