import React from 'react';
import {
    PriceContainer,
    HeaderImage,
    PriceDataContainer,
    PriceType,
    PriceDescription
} from './styles';

const PriceCard = ({ price }) => {
    return (
        <PriceContainer>
            <PriceDataContainer>
                <HeaderImage image={price?.image} />
                <PriceType>{price?.type}</PriceType>
                <PriceDescription>{price?.description}</PriceDescription>
            </PriceDataContainer>
        </PriceContainer>
    )
}

export default PriceCard;
