import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { PublicHeader, PublicFooter, LoginCard, RegisterCard, RecoverCard } from 'components';
import { 
    PublicContainer,
    HeaderContainer,
    MainContainer,
    DrawerContainer,
    BlurContent,
} from './styles';

const PublicLayout = ({ children }) => {
    const windowScroll = useRef(null);
    const history = useHistory();
    const [ showLogin, setShowLogin ] = useState(false);
    const [ showRegister, setShowRegister ] = useState(false);
    const [ showRecover, setShowRecover ] = useState(false);

    const closeDrawers = () => {
        setShowLogin(false);
        setShowRegister(false);
        setShowRecover(false);
    }

    const handleRegister = () => {
        setShowRegister(true);
        setShowLogin(false);
        setShowRecover(false);
    }

    const handleLogin = () => {
        setShowRegister(false);
        setShowLogin(true);
        setShowRecover(false);
    }

    const handleRecover = () => {
        setShowRegister(false);
        setShowLogin(false);
        setShowRecover(true);
    }

    useEffect(() => {
        const unlisten = history.listen(() => {
            if(windowScroll != null && windowScroll.current){
                windowScroll.current.scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return(
        <PublicContainer>
            {(showLogin || showRegister || showRecover) && 
                <BlurContent onClick={ closeDrawers } />
            }
            <HeaderContainer>
                <PublicHeader handleRegister={handleRegister} handleLogin={handleLogin}/>
            </HeaderContainer>

            <MainContainer ref={windowScroll}>
                {(children) && 
                    React.cloneElement(children, { handleRegister })
                }
                <PublicFooter/>
            </MainContainer>

            <DrawerContainer login showDrawer={showLogin}>
                <LoginCard handleRegister={handleRegister} handleRecover={handleRecover}/>
            </DrawerContainer>
            <DrawerContainer recover showDrawer={showRecover}>
                <RecoverCard handleLogin={handleLogin} closeDrawers={closeDrawers}/>
            </DrawerContainer>
            <DrawerContainer showDrawer={showRegister}>
                <RegisterCard handleLogin={handleLogin} />
            </DrawerContainer>
        </PublicContainer>
    )
}

export default PublicLayout;