import styled from 'styled-components';

export const ButtonContainer = styled('button').withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['isGray'].includes(prop) && defaultValidatorFn(prop),
    })`
    background: ${props => props.isGray ? 'transparent':'#3F9E34'};
    border-radius: 100px;
    height: 48px;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.isGray ? '#646761':'#ffffff'};
    opacity: 1;
    width: 100%;
    ${props => props.isGray && 'border-color: #646761;'};

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;
