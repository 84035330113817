import styled from 'styled-components';

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    text-align: center;
    color: #646761;
    margin-top: 0px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const FormContainer = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 16px;
`;

export const ErrorParagraph = styled.p`
    color: #DA2222;
    margin: 0px;
`;