import React from 'react';
import CompetitionColors from 'constants/CompetitionColors';
import { 
    CompetitionBack,
    CompetitionName
} from './styles';

const Competition = ({competition}) => {
    return (
        <div>
            <CompetitionBack bgColor={CompetitionColors[competition.competition.permalinkEs]} >
                <CompetitionName>
                    <img src={competition.mediaUrl} alt={competition.competition.nameEs} />
                </CompetitionName>
                <h1>{competition.competition.nameEs}</h1>
            </CompetitionBack>
        </div>
    );
}
 
export default Competition;