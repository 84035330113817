import React from 'react';
import { 
    BlackButton,
    Cover, 
    Container,
    CoverTxt,
    CoverButton,
    Arrow,
    RulesContainer,
    InsideContainer,
    TitleSection,
    SubtitleSection,
    RulesImg,
    TitleRulesItem,
    TxtRulesItem,
    RewardContainer,
    RewardsImg,
    RewardsTxt,
    RewardItemContainer,
    WhiteBackground
} from './styles';

import Rule1 from 'assets/item-1.png';
import Rule2 from 'assets/item-2.png';
import Rule3 from 'assets/item-3.png';
import CellPhone from 'assets/cellPhone.svg';

const Landing = ({ handleRegister }) => {
    return ( 
    <WhiteBackground>
        <Cover>
            <Container>
                <CoverTxt>
                    <span>Juega y gana </span>premios todas las semanas.
                </CoverTxt>
                <CoverButton onClick={handleRegister}>
                    ¡Juega ya!
                </CoverButton>
                <Arrow></Arrow>
            </Container>
        </Cover>
        <RulesContainer>
            <Container>
                <TitleSection>
                    Las reglas del juego
                </TitleSection>
                <InsideContainer>
                    <div>
                        <RulesImg>
                            <img src={Rule1} alt="registrate"/>
                        </RulesImg>
                        <TitleRulesItem>Regístrate</TitleRulesItem>
                        <TxtRulesItem>
                            ¡Quiniela juanfutbol es gratis y fácil de jugar! Gana premios semanales y a final de temporada.
                        </TxtRulesItem>
                    </div>
                    <div>
                        <RulesImg>
                            <img src={Rule2} alt="Crea tus torneos"/>
                        </RulesImg>
                        <TitleRulesItem>Crea tus torneos</TitleRulesItem>
                        <TxtRulesItem>
                            Puedes crear torneos para competir con tus amigos. Realiza con ellos una apuesta real para hacerlo más rifado.
                        </TxtRulesItem>
                    </div>
                    <div>
                        <RulesImg>
                            <img src={Rule3} alt="Haz tu Voto"/>
                        </RulesImg>
                        <TitleRulesItem>Haz tu voto</TitleRulesItem>
                        <TxtRulesItem>
                            Demuestra tu conocimiento. ¡Haz tus pronósticos y gana con Quiniela juanfutbol! Juega cada semana.
                        </TxtRulesItem>
                    </div>
                </InsideContainer>
                <BlackButton onClick={handleRegister}>
                    ¡Juega ya!
                </BlackButton>
            </Container>
        </RulesContainer>
        <RewardContainer>
            <Container>
                <TitleSection>
                    Increíbles premios
                </TitleSection>
                <SubtitleSection>
                    Disfruta de lo que juanfutbol tiene para ti. ¡Participa y gana!
                </SubtitleSection>
            </Container>
            <Container>
                <InsideContainer>
                    <RewardsImg>
                        <img src={CellPhone} alt="CellPhone"/>
                    </RewardsImg>
                    <RewardItemContainer>
                        <RewardsTxt>
                            Puedes crear torneos para competir con tus amigos. Realiza con ellos una apuesta real para hacerlo más rifado.
                        </RewardsTxt>
                        <BlackButton onClick={handleRegister}>
                            ¡Juega ya!
                        </BlackButton>
                    </RewardItemContainer>
                </InsideContainer>
            </Container>
        </RewardContainer>
    </WhiteBackground> 
    );
}
 
export default Landing;