import React, { useEffect } from 'react';
import { Radio } from 'components';
import { Controller } from "react-hook-form";

import { 
  Container,
  StyledRadioGroup,
  SkeletonRadioGroup
} from './styles';

import { 
    Skeleton
} from '@material-ui/lab';

const MatchRadio = ({ match, control, unregister, userPick, noOpacity=false }) => {
  let disabled = false
  let pointsRule = {}

  if(match){
    disabled = (match.status >= 2 && match.status <= 8) || match.status === 11;
    pointsRule = (match.featured) ? JSON.parse(match.pointsRuleJson) : {}
  }

  useEffect(() => {
    return (() => {
      if(control && unregister && match){
        unregister(match.uuid);
      }
    });
  },[match, control, unregister]);

  const getPoints = (point)  => {
    if(pointsRule[point] !== undefined) 
      return (pointsRule[point] === '+1') ?
        pointsRule[point] + ' pt'
      :
        pointsRule[point] + ' pts'
    return ''
  }

  const getRadioGroupComponent = (onChange) => {
    if(match){
      return (
        <StyledRadioGroup name={`${match?.uuid}-pick`} defaultValue={userPick?.result.toString()} onChange={onChange}>
          <Container>
            <Radio withLabel
              value="1"
              label={getPoints('homePointsStr')}
              selected={userPick?.result.toString()==="1"}
              disabled={disabled}
              correct={userPick?.correctPrognostic}
              noOpacity={noOpacity}
            />
            <Radio withLabel
              value="2"
              label={getPoints('drawPointsStr')}
              selected={userPick?.result.toString()==="2"}
              disabled={disabled}
              correct={userPick?.correctPrognostic}
              noOpacity={noOpacity}
            />
            <Radio withLabel
              value="3"
              label={getPoints('visitorPointsStr')}
              selected={userPick?.result.toString()==="3"}
              disabled={disabled}
              correct={userPick?.correctPrognostic}
              noOpacity={noOpacity}
            />
          </Container>
        </StyledRadioGroup>
      );
    } else{
      return (
        <SkeletonRadioGroup>
          <div><Skeleton variant="circle" /></div>
          <div><Skeleton variant="circle" /></div>
          <div><Skeleton variant="circle" /></div>
        </SkeletonRadioGroup>
      );
    }
    
  }

  return (
    <>
      {(control) ?
        <Controller control={control} name={match?.uuid} render={({ field: { onChange, value } }) => getRadioGroupComponent(onChange)}/>
      :
        getRadioGroupComponent()
      }
    </>
  );
}
 
export default MatchRadio;