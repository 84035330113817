import styled from 'styled-components';
import { ReactComponent as NewIcon } from 'assets/new.svg';
import { ReactComponent as Crown } from 'assets/crown.svg';

export const GroupContainer = styled.div`
    width: ${props => props.isTop ? '140px' : '280px' };
    border-radius: 12px;
    background-color: #ffffff;
    overflow: hidden;

    @media (min-width: 769px)  {
        width: ${props => props.isTop ? '260px' : '520px' };
    }
`;

export const HeaderImage = styled.div`
    background-image: url(${props => props.image});
    width: 100%;
    height: 117px;
    background-size: cover;
    background-position: center center;
    position: relative;

    @media (min-width: 769px)  {
        height: 234px;
    }
`;

export const New = styled(NewIcon)`
    position: absolute;
    left: -4px;
    top: 0px;
`;

export const GroupDataContainer = styled.div`
    width: 100%;
    padding: 9px 9px 5px 9px;
    font-family: 'Montserrat';
    font-style: normal;
    line-height: 17px;
    letter-spacing: 0.175714px;
    color: #000000;

    display: grid;
    grid-template-columns: ${props => props.isTop ? '100%' : 'auto auto' };
    grid-template-rows: ${props => props.isTop ? 'auto auto auto' : 'auto auto' };
    grid-template-areas: ${props => props.isTop ? 
        `'name''creator''participants'`
        :
        `'name name''creator participants'` };
    grid-gap: 0px;

    @media (min-width: 769px)  {
        padding: 15px 15px 9px 15px;
        grid-gap: 6px;
    }
`;

export const GroupName = styled.div`
    font-size: ${props => props.isTop ? '12px' : '14px' };
    font-weight: 700;
    grid-area: name;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (min-width: 769px)  {
        font-size: ${props => props.isTop ? '16px' : '18px' };
    }
`;

const GroupInfo = styled.div`
    font-size: 9px;
    font-weight: 600;

    @media (min-width: 769px)  {
        font-size: 12px;
    }
`;

export const GroupCreator = styled(GroupInfo)`
    grid-area: creator;
    text-align: left;
`;

export const GroupParticipants = styled(GroupInfo)`
    grid-area: participants;
    text-align: ${props => props.isTop ? 'left' : 'right' };
`;

export const CrownIcon = styled(Crown)`
    width: 9px;
    height: 8px;
    margin-top: -3px;

    @media (min-width: 769px)  {
        width: 12px;
        height: 11px;
        margin-top: -4px;
    }
`;