import PropTypes from 'prop-types';
import { UserIcon, MatchRadio } from 'components';
import {
    ContainerMatch,
    DateInfoContainer,
    HourInfo,
    HourFinal,
    DayInfo,
    MonthInfo,
    HomeTeamContainer,
    HomeName,
    BackLive,
    MatchRadioContainer,
    AwayTeamContainer,
    AwayName,
    Outstanding,
    TeamAsset
} from './styles';
import star from '../../assets/star.svg';

import { 
    Skeleton
} from '@material-ui/lab';

const Match = ({ match, control, unregister, userPick, noOpacity }) => {
    let disabled = false;
    let hour = '';
    let minutes = '';
    let month = '';
    let day = '';

    if(match) {
      let dateObject = new Date(match.dateScheduled.replace(/-/g, "/"));
      let time = dateObject.getTime();
      let newDate = new Date(time - 18000000);//21600000 = horario invierno
      
      const MESES = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
      ];

      month = MESES[newDate.getMonth()].slice(0, 3);
      hour = newDate.getHours();
      day = newDate.getDate();
      minutes = newDate.getMinutes();
      if (minutes >= 0 && minutes <=9) {
          minutes=`0${minutes}`;
      }
      
      disabled = (match.status >= 2 && match.status <= 8) || match.status === 11
    }

    function renderDate() {
      if ((match.status >= 9 && match.status <= 10) || match.status === 1) {
        return (
          <>
            <HourInfo>{hour}:{minutes}</HourInfo>
            <DayInfo>{day}</DayInfo>
            <MonthInfo>{month}</MonthInfo>
          </>
        )
      } else if ((match.status >= 5 && match.status <= 8) || match.status === 11) {
        return (
          <BackLive></BackLive>
        )
      } else if (match.status === 3) {
        return (
          <>
            <HourFinal>{hour}:{minutes}</HourFinal>
            <DayInfo>{day}</DayInfo>
            <MonthInfo>{month}</MonthInfo>
          </>
        )
      }
    }

    return (
      <ContainerMatch>
        <DateInfoContainer disabled={disabled} noOpacity={noOpacity}>
          { (match) ?
            renderDate()
          :
            <Skeleton variant="rect" />
          }
        </DateInfoContainer>

        <HomeTeamContainer>
          <HomeName>
            { (match) ? 
              match.homeTeam ? (match.homeTeam.nickName ? match.homeTeam.nickName : match.homeTeam.nameEs) : "Por Definir"
            :
              <Skeleton />
            }
          </HomeName>
          { (match) ?
            ((!match.homeTeam?.mediaUrl) ?
                <UserIcon firstName={(match.homeTeam ? (match.homeTeam.nickName ? match.homeTeam.nickName : match.homeTeam.nameEs) : "Por Definir")} lastName={''} />
              :
                <TeamAsset src={match.homeTeam.mediaUrl} />
            )
          :
            <Skeleton variant="circle"/>
          }
        </HomeTeamContainer>

        <MatchRadioContainer>
          <MatchRadio match={match} userPick={userPick} control={control} unregister={unregister} noOpacity={noOpacity}/>
        </MatchRadioContainer>

        <AwayTeamContainer>
          { (match)?
            ((!match.awayTeam?.mediaUrl) ?
                <UserIcon firstName={(match.awayTeam ? (match.awayTeam.nickName ? match.awayTeam.nickName : match.awayTeam.nameEs) : "Por Definir")} lastName={''} />
              :
                <TeamAsset src={match.awayTeam.mediaUrl} />
            )
          :
            <Skeleton variant="circle"/>
          }
          <AwayName>
            { (match) ? 
              match.awayTeam ? (match.awayTeam.nickName ? match.awayTeam.nickName : match.awayTeam.nameEs) : "Por Definir"
            :
              <Skeleton />
            }
          </AwayName>
        </AwayTeamContainer>

        <Outstanding disabled={disabled} noOpacity={noOpacity}>
        { (match) && 
            (match.pointsRuleJson) &&
              <><img alt='star' src={star} /><div>Destacado</div></>
        }
        </Outstanding>

      </ContainerMatch>
    );
}

Match.propTypes = {
    match: PropTypes.object,
    userPick: PropTypes.object,
    noOpacity: PropTypes.bool,
    control: PropTypes.object,
    unregister: PropTypes.func,
};

Match.defaultProps = {
    noOpacity: false,
};
 
export default Match;