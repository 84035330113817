import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetSeasonPlaying } from 'services/Private/useGameService';
import { Competition } from 'components';

const Picker = () => {
    const { data:dataList, error: errorList, getSeasonPlaying } = useGetSeasonPlaying();
    const { pickerType } = useParams();

    const callAPI = React.useCallback(async() => {
        await getSeasonPlaying();
    }, [getSeasonPlaying]);

    useEffect( () => {
        callAPI();
    }, [callAPI]);

    return (
        <>
        {errorList && console.log(errorList)}
        {dataList && dataList.data.map(competition => (
            <div key={competition.uuid}>
                <Link to={`${pickerType}/${competition.competition.permalinkEs}`}>
                    <Competition 
                        competition={competition}
                    />
                </Link>
            </div>
        ))}
        </>
    )
}

export default Picker;
