import styled from 'styled-components';
import { 
    Radio as RadioUI, 
    FormControlLabel as FormControlLabelUI
} from '@material-ui/core';

export const StyledFormControlLabel = styled(FormControlLabelUI).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          (!['noOpacity'].includes(prop) && !['correct'].includes(prop)),
    })`
    
    &.MuiFormControlLabel-labelPlacementBottom{
        margin-left: auto;
        margin-right: auto;
    }

    margin: 0px auto;
    opacity: ${props => (props.disabled && !props.noOpacity) ? '0.3' : '1'}

`;

export const StyledRadioIcon = styled.span`
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #e0e0e0;

    $root.Mui-focusVisible & {
        outline: 2px auto rgba(224,224,224,.6);
        outline-offset: 2;
    }

    input:hover ~ & {
        background-color: #e0e0e0;
    }

    input:disabled ~ & {
        box-shadow: none;
        background: rgba(206,217,224,.5);
    }
`;
    
export const StyledRadioIconChecked = styled(StyledRadioIcon)`
    background-color: #e0e0e0;
    background-image: linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0));
    &:before {
        display: block;
        width: 16px;
        height: 16px;
        background-image: radial-gradient(#3f9e34,#3f9e34 42%,transparent 32%);
        content: "";
    }

    input:hover ~ & {
        background-color: #e0e0e0;
    }

    .MuiFormControlLabel-label {
        color: #3f9e34;
    }
`;

export const StyledRadio = styled(RadioUI).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          (!['correct'].includes(prop)),
    })`

    &:hover {
        background-color: transparent
    }

    ${StyledRadioIconChecked}:before {
        background-image: ${props => {
            if(props.correct !== undefined)
                return props.correct === false ? 
                    'radial-gradient(#DA2222,#DA2222 42%,transparent 32%)'
                : 
                    'radial-gradient(#3f9e34,#3f9e34 42%,transparent 32%)'
                }
        };
    }

    & + .MuiFormControlLabel-label, & + .MuiFormControlLabel-label.Mui-disabled{
        color: #646761;
        font-weight: 500;
        width: 42px;
        text-align: center;
        padding: 0px 5px;
        border-radius: 8px;
        font-size:11px;

        ${props => {
            if(props.correct !== undefined && props.selected) 
                return props.correct === false ? 
                    `background-color: #DA2222;
                    color: #fff;`
                : 
                    `background-color: #3f9e34;
                    color: #fff;`
        }
    }
`;