import React, { useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { UserIcon, UserDropdown } from 'components';
import {
    UserInfoContainer,
    Menu,
    MenuButton
} from './style';

const UserInfo = ({ toggleSideBar }) => {
    const { user } = useContext(UserContext);
    return (
        <UserInfoContainer>
            <UserDropdown completeName={user?.nameComplete} />
            <UserIcon firstName={user?.firstName} lastName={user?.lastName} />
            <MenuButton onClick={toggleSideBar}>
                <Menu />
            </MenuButton>
        </UserInfoContainer>
        
    )
}

export default UserInfo;