import styled from 'styled-components';

export const FaqContainer = styled.div`
    position: relative;
`;

export const Container = styled.div`
    width: 100%;
`;

export const TitleFaq = styled.div`
    width: 100%;
    height: 36px;
    border-left: 3px solid #3F9E34;
    padding: 0px 20px;
    color: #3F9E34;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
`;

export const Separator = styled.div`
    width: 100%;
    height: ${props => `${props.height}px`};
`;

export const WhenNoHeader = styled.div`
    @media (min-width: 769px)  {
        display: none;
    }
`;