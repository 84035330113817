import styled from 'styled-components';
import {RadioGroup as RadioGroupUI} from '@material-ui/core';

export const Container = styled.div`
    display:flex;
    justify-content: space-between;

    > * {
        max-width: 33%;
    }
`;

export const StyledRadioGroup = styled(RadioGroupUI)`
    width:100%;
    min-width:125px;
`;

export const SkeletonRadioGroup = styled.div`
    width:100%;
    min-width:125px;
    display:flex;
    justify-content: space-between;

    > div {
    	width:100%
    }

    > div > .MuiSkeleton-circle {
        width:16px;
        height:16px;
        margin: 9px auto;

    }
`;