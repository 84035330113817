import React from 'react';
import { UserProvider } from 'context/UserContext';
import { MaterialProvider } from 'context/ThemeContext';
import { PrivateRoute, PublicRoute } from 'layout';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { 
  Groups,
  Picker,
  Ranking,
  Results,
  Login,
  Landing,
  Register,
  Matchdays,
  Confirm,
  Recover,
  Reset,
  Prices,
  Faq,
  Legals,
  Profile,
  Password
} from 'sections';
import * as Routes from 'constants/Routes';

function App() {
  return (
    <MaterialProvider>
      <UserProvider>
        <Router>
          <Switch>
            <PublicRoute exact path={Routes.HOME} components={Landing} />
            <PublicRoute exact path={Routes.FAQ} components={Faq} />
            <PublicRoute exact path={Routes.LEGALS} components={Legals} />

            <Route exact path={Routes.LOGIN} component={Login} />
            <Route exact path={Routes.REGISTER} component={Register} />
            <Route exact path={Routes.CONFIRM} component={Confirm} />
            <Route exact path={Routes.RECOVER} component={Recover} />
            <Route exact path={Routes.RESET} component={Reset} />

            <PrivateRoute exact path={Routes.PROFILE} components={Profile} />
            <PrivateRoute exact path={Routes.PASSWORD} components={Password} />
            <PrivateRoute exact path={`:pickerType(${Routes.POOLS}|${Routes.TOP_TEN_ID}|${Routes.AWARDS_ID}|${Routes.GROUPS_ID}|${Routes.RESULTS_ID})?`} components={Picker} />
            <PrivateRoute path={`:pickerType(${Routes.POOLS})/:permalink`} components={Matchdays} />
            <PrivateRoute path={`:pickerType(${Routes.TOP_TEN_ID})/:permalink`} components={Ranking} />
            <PrivateRoute path={`:pickerType(${Routes.AWARDS_ID})/:permalink`} components={Prices} />
            <PrivateRoute path={`:pickerType(${Routes.GROUPS_ID})/:permalink`} components={Groups} />
            <PrivateRoute path={`:pickerType(${Routes.RESULTS_ID})/:permalink/:matchday?`} components={Results} />
          </Switch>
        </Router>
      </UserProvider>
    </MaterialProvider>
    
  );
}

export default App;
