import { useRef, useEffect } from "react"

const URL_REGEX = /url\(#([-_A-Za-z0-9]*)\)/

export default function PrefixSvgIds(file, prefix) {
  const ref = useRef()

  useEffect(() => {
    const rand =  Math.floor(1000 + (Math.random() * (9999-1000)));

    const descendants = ref.current.querySelectorAll("svg *")
    descendants.forEach((descendant) => {
      for (const attr of descendant.attributes) {
        // Replace things like id="#a"
        if (attr.name === "id") {
          if (attr.value.includes(prefix)) return
          const newId = `${prefix}_${rand}_${attr.value}`
          descendant.setAttribute(attr.name, newId)
        }

        // Replace things like fill="#a"
        const match = attr.value.match(URL_REGEX)
        if (match) {
          if (attr.value.includes(prefix)) return
          const newId = `url(#${prefix}_${rand}_${match[1]})`
          descendant.setAttribute(attr.name, newId)
        }
      }
    })
  }, [prefix])

  return <span ref={ref}>{file}</span>
}