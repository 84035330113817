import React from 'react';
import { 
    CardContainer,
    CardHeader,
    CardLogo,
    CardMain,
    ArrowMenu,
    CardBody 
} from './styles';
import PrefixSvgIds from 'utils/PrefixSvgIds'


const Card = ({children, arrowLeft, arrowRight, hasArrow = true, hasLogo = true, hasPadding = true }) => {
    const arrowStyle = {
        left: arrowLeft ? arrowLeft: 0,
        right: arrowRight ? arrowRight: 0
    }

    return(
        <CardContainer>
            { (hasArrow) &&
                <ArrowMenu style={arrowStyle} />
            }
            <CardMain hasPadding={hasPadding}>
                { (hasLogo) && 
                    <CardHeader>
                        {PrefixSvgIds(<CardLogo />, "my_prefix")}
                    </CardHeader>
                }
                <CardBody>
                    {children}
                </CardBody>
            </CardMain>
        </CardContainer>
        
    )
};

export default Card;