export const Countries = [
    {'value':'6e8466f1-a8fd-4fb3-aa6a-1ae6fa401eb2', 'name':'Afganistán'},
    {'value':'7c1bf988-b34c-4884-b9dc-2f66406ee7c1', 'name':'Albania'},
    {'value':'c73c8eb4-a285-4938-9bcf-97a66dedf0ce', 'name':'Alemania'},
    {'value':'2431d791-7424-49a0-85d1-ee6d86a2a81c', 'name':'Andorra'},
    {'value':'be9b7466-1e85-426e-bd50-455057ffc373', 'name':'Angola'},
    {'value':'8d8f63e6-48f4-423d-a4f0-e0ef589195b1', 'name':'Anguila'},
    {'value':'f1a0c385-17ba-4d9d-8557-4bf101d78abc', 'name':'Antártida'},
    {'value':'c51c22ee-b03e-48d1-8d94-d0068857365b', 'name':'Antigua y Barbuda'},
    {'value':'2511da40-24ba-4685-b181-4442d9e334cb', 'name':'Antillas Holandesas'},
    {'value':'57c0fb10-ea05-4898-b031-41996c5a3d39', 'name':'Arabia Saudita'},
    {'value':'4cfdad4e-b55e-492e-82d2-744666c6fd33', 'name':'Argelia'},
    {'value':'cf8c29ea-d3a3-4641-a485-8f2d444ecec8', 'name':'Argentina'},
    {'value':'c0455a82-139b-4e48-abcc-dbf44f5bca32', 'name':'Armenia'},
    {'value':'8962c053-5969-41c2-a172-eb74d9d9716b', 'name':'Aruba'},
    {'value':'2a088443-45bb-45ef-a89e-6509a3c28f9b', 'name':'Australia'},
    {'value':'781c6bab-c7bd-4588-a1d6-b02a9e3783da', 'name':'Austria'},
    {'value':'ef28f7f7-1ea6-41a7-b94b-3578a5d5afb2', 'name':'Azerbaiyán'},
    {'value':'6c39a72f-ed17-4893-b4d9-e18cec907cb1', 'name':'Bahamas'},
    {'value':'43538664-f255-446d-a518-21393885d1a0', 'name':'Bahráin'},
    {'value':'21b78f76-8d9e-4e4c-a0c8-07aeea9906f6', 'name':'Bangladesh'},
    {'value':'228cd97e-c531-402a-8596-15a5988df99f', 'name':'Barbados'},
    {'value':'0997aefc-a2a4-4bb6-85f7-2f70d6c4d9ac', 'name':'Bélgica'},
    {'value':'5e68dd29-d2d0-4493-b657-a2a59dc7e46d', 'name':'Belice'},
    {'value':'0af6e7db-8482-49bc-a9f1-41d510fae472', 'name':'Benín'},
    {'value':'69b8b6cc-cb58-4bfd-8f18-f0bfa50a7ef9', 'name':'Bermudas'},
    {'value':'bb8c4146-205a-4b7a-bb78-560735710bfb', 'name':'Bielorrusia'},
    {'value':'9c10be3d-2155-43d4-9638-2b485042a59d', 'name':'Bolivia'},
    {'value':'401484a1-c924-4294-bf1c-4f7103bc0879', 'name':'Bonaire, San Eustaquio y Saba'},
    {'value':'716abb12-abf9-47e1-a81d-6318b572b65c', 'name':'Bosnia y Hercegovina'},
    {'value':'c2d5a553-df9c-4264-ad70-9eefd5b33039', 'name':'Botsuana'},
    {'value':'351a9aa4-2db3-4d0b-a6c3-ca2471faf993', 'name':'Brasil'},
    {'value':'95236675-c9c4-4cc9-9e7f-a7a657ef0508', 'name':'Brunéi'},
    {'value':'7e60d5fc-3bae-4d4b-85ed-ad7ff43c0b4a', 'name':'Bulgaria'},
    {'value':'0be47f85-9ae7-4fb2-acfb-105cff069038', 'name':'Burkina Faso'},
    {'value':'e8900b5e-906b-4c8f-923c-019ec1485239', 'name':'Burundi'},
    {'value':'484b8607-3122-4634-a34f-ddb7bd5dcb88', 'name':'Bután'},
    {'value':'7e9710a6-6c18-4353-97c6-e574f915bf4a', 'name':'Cabo Verde'},
    {'value':'f36ae8c3-cf7a-4b23-a803-2795449e2993', 'name':'Camboya'},
    {'value':'4f7d3b85-e303-4261-9124-fde2eb80e6d6', 'name':'Camerún'},
    {'value':'11834b15-2bd3-4e99-af5a-06b7f9b96dae', 'name':'Canadá'},
    {'value':'7870c27b-379b-49be-8a97-bc59774ec3b6', 'name':'Chad'},
    {'value':'6b3ff700-52b0-41dc-8204-4576ec8b0e9b', 'name':'Chequia'},
    {'value':'b5fa1ca1-998c-43a4-89fb-b304f1183c50', 'name':'Chile'},
    {'value':'6fef7608-8fa6-49c6-9e53-23799e7d1e55', 'name':'China'},
    {'value':'d8650ce5-cb2a-4895-9d6c-5679ec168a5e', 'name':'Chipre'},
    {'value':'829816f4-ce31-44fa-a521-81e9fdbf27d1', 'name':'Ciudad del Vaticano'},
    {'value':'ded8816f-7a19-4a6a-b030-0d427770243a', 'name':'Colombia'},
    {'value':'057a7cf1-7421-4958-ac95-50ee2528947c', 'name':'Comores'},
    {'value':'d46bbc69-e92e-4212-b61f-e118a2f3f050', 'name':'Congo'},
    {'value':'204d77f4-2e01-4745-98ca-d7c144b254ca', 'name':'Corea del Norte'},
    {'value':'d86957ce-94e8-481b-87c7-60cb850f48cc', 'name':'Corea del Sur'},
    {'value':'92cb47d7-edad-4b30-9b14-704e309bb2b9', 'name':'Costa de Marfil'},
    {'value':'d99a4d9e-3408-4e41-bd2c-b27a48233885', 'name':'Costa Rica'},
    {'value':'f5daa89c-906c-4995-ae5f-c4d36029a654', 'name':'Croacia'},
    {'value':'94cbdb0f-5745-41c0-8128-48d0c8e4e491', 'name':'Cuba'},
    {'value':'82fd6671-1136-4b09-8e78-f79f96208ec6', 'name':'Curaçao'},
    {'value':'663c693e-6b5e-465e-809e-f27658d55bc6', 'name':'Dinamarca'},
    {'value':'fe6d2d77-6180-4a95-a48d-f323ff9f81b0', 'name':'Dominica'},
    {'value':'ecd57c64-b12a-4659-ad49-461573f01c2b', 'name':'Ecuador'},
    {'value':'dcc07197-9046-4b37-b5a0-812407ffe8bd', 'name':'Egipto'},
    {'value':'05e171f3-d3af-483a-b96d-a8ce69462fe6', 'name':'El Salvador'},
    {'value':'1083ebac-fe3b-4d67-aba7-95f1bda8c71c', 'name':'Emiratos Árabes Unidos'},
    {'value':'28a96e35-7e6e-4891-bc6a-f05613bbf32a', 'name':'Eritrea'},
    {'value':'fdeb56ec-e43c-4277-ac9c-b60ca2a65aa3', 'name':'Eslovaquia'},
    {'value':'a674d31c-569e-43f2-a2f1-73dc07ef24a4', 'name':'Eslovenia'},
    {'value':'93894862-419a-44f9-aa55-b3f0437c37cd', 'name':'España'},
    {'value':'dd22a9f2-32f6-4a1d-97cf-c4ae160c5a93', 'name':'Estados Unidos'},
    {'value':'fa1451b8-0289-4193-a1cf-26abbd5dc493', 'name':'Estonia'},
    {'value':'89b36756-580a-4341-99a5-fe1ffcaaa903', 'name':'Etiopía'},
    {'value':'52d41fca-a8fe-425e-a30c-1a64372d36d8', 'name':'Filipinas'},
    {'value':'d294517e-e021-435f-abde-6ac61f2242b2', 'name':'Finlandia'},
    {'value':'4b8591b6-d86b-4a38-974a-219f32b17ed7', 'name':'Fiyi'},
    {'value':'97481476-6c83-41b6-bb2b-6db8e37ab29a', 'name':'Francia'},
    {'value':'42777aa4-224f-49f5-882f-ff35940d41b6', 'name':'Gabón'},
    {'value':'0bab759c-0447-4d34-851a-0e3bc8b56ed8', 'name':'Gambia'},
    {'value':'999cab09-7b3b-47fc-8694-8d7ae4aea632', 'name':'Georgia'},
    {'value':'db0fbbcb-a80a-4c19-ba02-641ba56911dd', 'name':'Ghana'},
    {'value':'1f157efe-4e6a-432e-8283-d8ab6a6032fa', 'name':'Gibraltar'},
    {'value':'7c1d1800-2386-42b0-bc6e-b7807b55bb61', 'name':'Granada'},
    {'value':'4bbdc88a-3be8-4846-b51d-8c3466d3f945', 'name':'Grecia'},
    {'value':'b27cf9cd-2834-47be-a38d-cf450f501393', 'name':'Groenlandia'},
    {'value':'b936e56c-386c-454b-835d-4ca81431bf6b', 'name':'Guadalupe'},
    {'value':'d077ba62-a8c0-46d5-850d-f167a7774cd4', 'name':'Guam'},
    {'value':'db5d157e-887a-49e0-b10a-debb3f915185', 'name':'Guatemala'},
    {'value':'ff36b2e1-794e-4921-8fd3-6b0b779f9004', 'name':'Guayana Francesa'},
    {'value':'04aa5625-60f7-4ddb-9492-c13997e955b3', 'name':'Guernsey'},
    {'value':'497b4539-ebe7-408c-b776-ac74c28ba8e6', 'name':'Guinea'},
    {'value':'70ea3e10-8fd4-4afc-bc7d-6ac59495e7ea', 'name':'Guinea Ecuatorial'},
    {'value':'afc44110-92fa-41e7-b333-59bfbf649e3c', 'name':'Guinea-Bissau'},
    {'value':'48d8c08f-82bd-4c25-9283-8eec709e7573', 'name':'Guyana'},
    {'value':'5f910faf-2baa-457b-bf76-3010073f811b', 'name':'Haití'},
    {'value':'7aa48ba2-823a-4c12-8f24-e3c4f7cbef2d', 'name':'Holanda'},
    {'value':'0e2f139b-8c18-4f86-a1ba-865af260912f', 'name':'Honduras'},
    {'value':'6fc9ea39-ead9-4203-b619-48f890142e7c', 'name':'Hong Kong'},
    {'value':'51bbcd69-f94d-47ba-81a7-12a84116c492', 'name':'Hungría'},
    {'value':'bc72de63-73a5-48d0-acae-c11a5e47bf60', 'name':'India'},
    {'value':'a72ddc3d-d202-4d8a-b92a-0da2de0305bb', 'name':'Indonesia'},
    {'value':'67c1da31-05d7-4671-a5d1-b6db60c537a8', 'name':'Irán'},
    {'value':'2875849f-f037-4187-9513-295ceb643ed1', 'name':'Iraq'},
    {'value':'c2b857f2-1d6b-4365-a828-63c498858f40', 'name':'Irlanda'},
    {'value':'501d2efb-4e2c-411b-8d42-0271b9ce2f43', 'name':'Isla Bouvet'},
    {'value':'1c835606-91e7-430b-a11c-5baf91ae9284', 'name':'Isla Christmas'},
    {'value':'c9ae2d06-2a5c-4db1-bc1a-cf7cf01dcf52', 'name':'Isla de Man'},
    {'value':'da043b42-7e3c-474e-97ec-d73085295bee', 'name':'Isla Norfolk'},
    {'value':'e1f03786-1ec9-43b2-8ed1-311ab594d19e', 'name':'Islandia'},
    {'value':'dcd31cba-1720-4441-8e2e-b1a5c060dd77', 'name':'Islas Aland'},
    {'value':'f7ba612e-f4bc-41b4-b91e-f17fe40b64e8', 'name':'Islas Caimán'},
    {'value':'a3992152-b916-4033-8271-f83fe25d8577', 'name':'Islas Cocos'},
    {'value':'a754087f-de4b-43d3-be7a-86eeb2e5589f', 'name':'Islas Cook'},
    {'value':'d887f14a-0985-4fe3-bde1-9de96e888410', 'name':'Islas Feroe'},
    {'value':'89ecd55b-20d1-40d8-8eb2-76ebd1fec124', 'name':'Islas Georgia del Sur y Sandwich del Sur'},
    {'value':'7360fca5-0b86-42bc-bd5f-ef2d91e0d696', 'name':'Islas Heard y McDonald'},
    {'value':'1388e2bc-2550-4db9-b380-23ca18e4bcff', 'name':'Islas Malvinas'},
    {'value':'32e3807c-0126-4867-b031-4df2474358b3', 'name':'Islas Marianas del Norte'},
    {'value':'c2f606b9-c20a-4e36-a669-5f6c317f26ff', 'name':'Islas Marshall'},
    {'value':'10c82ee3-7720-44b8-9cd8-06053d136d31', 'name':'Islas menores alejadas de los Estados Unidos'},
    {'value':'896d90cf-4c89-4e70-ab02-3e6207e409b2', 'name':'Islas Pitcairn'},
    {'value':'0e3cedc3-be06-467d-b5e5-78e08db9bf35', 'name':'Islas Salomón'},
    {'value':'f6a9141b-f684-4316-b776-ab94d05b7d18', 'name':'Islas Turcas y Caicos'},
    {'value':'ca09ee08-2fd5-475e-ba75-ce4c4ecc84b0', 'name':'Islas Vírgenes Americanas'},
    {'value':'7f22866a-f32a-4b56-9f5d-ae503c11bca4', 'name':'Islas Vírgenes Británicas'},
    {'value':'5f479ff6-15d7-4472-8028-80593b000cb0', 'name':'Israel'},
    {'value':'f7e3badb-9852-4a29-828c-330b13202777', 'name':'Italia'},
    {'value':'6f27491d-88c8-4639-bcf6-969044a14385', 'name':'Jamaica'},
    {'value':'a5a926f8-b93d-4c57-a3ff-4140c883edbc', 'name':'Japón'},
    {'value':'74faa5ce-ebf3-40af-a0c5-ac3a1fd702e3', 'name':'Jersey'},
    {'value':'9bc2b5ef-074d-4ae6-9c37-d415bf371336', 'name':'Jordania'},
    {'value':'95221bd0-d155-4226-9e60-e772214706d4', 'name':'Kazajstán'},
    {'value':'476adbc5-719d-481a-a11b-7dd33b1fd80a', 'name':'Kenia'},
    {'value':'05ec951b-e488-4309-9ebb-f771c91f7693', 'name':'Kirguizistán'},
    {'value':'a70ee687-435f-4905-8593-95c472d4689f', 'name':'Kiribati'},
    {'value':'fa490275-85db-4ca0-86d1-e9ff0d07525b', 'name':'Kuwait'},
    {'value':'39c30023-5317-41e6-87b8-6c943c8525e9', 'name':'Laos'},
    {'value':'4a76420c-3b96-423d-83db-7ce467bce626', 'name':'Lesoto'},
    {'value':'6bc7f11c-0e9f-46c7-b344-17647b097bab', 'name':'Letonia'},
    {'value':'fd7f01d1-4109-483d-a3c2-ccfa736137ba', 'name':'Líbano'},
    {'value':'1b2bd40a-5d28-470d-957d-9f006b792e19', 'name':'Liberia'},
    {'value':'1b784663-40f6-40ad-9512-2202f7412a96', 'name':'Libia'},
    {'value':'dfe24642-b459-4307-95a3-9a2275b80ce6', 'name':'Liechtenstein'},
    {'value':'7128d260-c652-4a93-a531-d68d545c33ec', 'name':'Lituania'},
    {'value':'2dbe3f8e-e211-4751-8d85-fbc2a3b932af', 'name':'Luxemburgo'},
    {'value':'fed6389e-9bb3-49be-bac1-c1e9fb591cb5', 'name':'Macao'},
    {'value':'7464fc89-c624-49d6-97c7-da64e2a27592', 'name':'Macedonia'},
    {'value':'95b84ad6-f22b-4e64-aabb-037f1ae55699', 'name':'Madagascar'},
    {'value':'9d097acb-6d1a-4c18-96cd-c6ddb7cc2e91', 'name':'Malasia'},
    {'value':'c0ae77ec-2676-415e-bb57-e80c0c2f2234', 'name':'Malaui'},
    {'value':'0e65d90f-448e-4ff0-8f38-a3a180a31565', 'name':'Maldivas'},
    {'value':'8ab3e057-482e-411a-a7fb-30f56aa151f0', 'name':'Malí'},
    {'value':'d393fd58-4502-4dc3-9d2f-23685b714833', 'name':'Malta'},
    {'value':'69ab9365-d393-4e00-b225-df8bd19483eb', 'name':'Marruecos'},
    {'value':'b90193b2-360e-4091-8c34-fa52ae132bcc', 'name':'Martinica'},
    {'value':'13384389-3f3b-403c-94fd-099d1a94bbc6', 'name':'Mauricio'},
    {'value':'55c41506-0cc1-4b43-ab0a-067a71eb55bf', 'name':'Mauritania'},
    {'value':'671f74cc-3091-4255-80cf-73009b620858', 'name':'Mayotte'},
    {'value':'96e08eb9-8ea2-4639-9301-2eae93fe5370', 'name':'México'},
    {'value':'f81694fb-0b48-4c6a-bcfe-cf17bc7db0bd', 'name':'Micronesia'},
    {'value':'cb2e9cdf-f9e3-44a3-b83d-71c41d957033', 'name':'Moldavia'},
    {'value':'6245ffb0-5ff0-4090-8cfc-046fd50976b9', 'name':'Mónaco'},
    {'value':'eed827bc-61ed-4ba6-8b2e-f9345e87d4aa', 'name':'Mongolia'},
    {'value':'8c582cc7-5148-437e-bdc0-8b51ba8b5ea2', 'name':'Montenegro'},
    {'value':'38243031-12a2-4211-9142-b63428fee8c2', 'name':'Montserrat'},
    {'value':'1466553f-7070-4c72-a2cc-6d507b01625e', 'name':'Mozambique'},
    {'value':'bed4d6e1-a75c-4564-9042-0d2dd336877a', 'name':'Myanmar'},
    {'value':'39106b0d-1e69-4883-92fd-af0b4b170c63', 'name':'Namibia'},
    {'value':'314af864-8db2-47a8-8917-9b3ea6dab562', 'name':'Nauru'},
    {'value':'979e0118-2bf6-4382-be20-0c3c02f34ab9', 'name':'Nepal'},
    {'value':'5c782d20-3220-42bc-9697-14c00d07b236', 'name':'Nicaragua'},
    {'value':'edc22634-306f-48e2-8bbf-46704c55df4b', 'name':'Níger'},
    {'value':'29ce0aed-dafd-4e72-b2f2-4aba618ac47e', 'name':'Nigeria'},
    {'value':'620baf90-f8bd-4682-9ea8-231a9a443c74', 'name':'Niue'},
    {'value':'c79558b2-7e1c-4036-bc13-7287791dfc01', 'name':'Noruega'},
    {'value':'8c000767-b00b-4ba0-9a9d-4f02a0d55bff', 'name':'Nueva Caledonia'},
    {'value':'1df5f21c-b71a-4c1c-8ab6-2772775e6dab', 'name':'Nueva Zelanda'},
    {'value':'44050ddf-7a4a-4725-9b22-20d424ef9ae3', 'name':'Omán'},
    {'value':'c1ccadc9-a1f0-498d-b7b8-d4734b32b4c0', 'name':'Palaos'},
    {'value':'d7c4fcff-1ac1-488f-bb07-7f19e9073622', 'name':'Palestina'},
    {'value':'67f71754-4ad3-4196-90bd-469980315c51', 'name':'Panamá'},
    {'value':'b8e7d0a8-6fb1-45ee-b1a4-3101bb8a1614', 'name':'Papúa New Guinea'},
    {'value':'44795225-db8d-4bc3-94d4-28d20640a057', 'name':'Paquistán'},
    {'value':'81c320fa-03d3-4029-8171-f4a684ce0ea5', 'name':'Paraguay'},
    {'value':'123cb51c-4b43-42fe-bf15-ebc4462e7b64', 'name':'Perú'},
    {'value':'eb43adaa-ff14-4f1c-b71e-073818f219d6', 'name':'Polinesia Francesa'},
    {'value':'62c536c8-f069-4f96-b8f3-74d5310b15d2', 'name':'Polonia'},
    {'value':'8f3030d6-8e03-4aeb-8b73-1df1fd3f2cdd', 'name':'Portugal'},
    {'value':'21f69c9b-1f05-4f0a-93ca-6921a7c89a7a', 'name':'Puerto Rico'},
    {'value':'dc74c7e5-012b-4c86-b998-47efbd3eaca9', 'name':'Qatar'},
    {'value':'32d79706-411f-4d74-9b0c-32cdd9009d21', 'name':'Reino Unido'},
    {'value':'0507ac12-6bb4-4250-8c7e-5a8b8d7ab7e8', 'name':'República Centroafricana'},
    {'value':'c1121af4-e016-4e93-bb14-bf6fcfe97cf2', 'name':'República Democrática del Congo'},
    {'value':'4f865216-fb46-4f94-9049-eca067e82995', 'name':'República Dominicana'},
    {'value':'00402ba9-ddaa-47c0-a212-146815477bff', 'name':'Reunión'},
    {'value':'bafa4ecf-5f61-4f05-9605-aea0ecb20bc8', 'name':'Ruanda'},
    {'value':'93c7b5c6-b3ad-44bc-951b-62f834ea4f61', 'name':'Rumania'},
    {'value':'52a197e3-8730-48bc-976c-d579ed13c14b', 'name':'Rusia'},
    {'value':'c863785e-050f-4fa0-855e-a0cddae2f258', 'name':'Sahara Occidental'},
    {'value':'c4ea18dc-6b73-47b2-976b-5e15513c3519', 'name':'Samoa'},
    {'value':'4d743fb2-15ad-496c-b415-64e56b08e211', 'name':'Samoa Americana'},
    {'value':'ec12b68c-6f16-4d48-b1bd-971022528a43', 'name':'San Bartolomé'},
    {'value':'a97353d3-261c-4322-af86-4edf88c49cb4', 'name':'San Cristóbal y Nieves'},
    {'value':'d3231c72-f5a3-4045-b47d-57670f964083', 'name':'San Marino'},
    {'value':'40db8407-52cd-497f-8485-46aaa6161b81', 'name':'San Martín'},
    {'value':'afd1c414-4de7-4774-9ac7-45f7c210b80e', 'name':'San Martín (región holandesa)'},
    {'value':'098d1d60-2e35-4195-a745-b57ad9fe090f', 'name':'San Pedro y Miquelón'},
    {'value':'a083c7d6-4b2e-48b5-bb40-9177971f5fb9', 'name':'San Vicente y las Granadinas'},
    {'value':'6c4b85bf-4d19-4062-a2cb-2d995734ca94', 'name':'Santa Helena'},
    {'value':'f98e02a6-ff0e-4274-bb61-1b1a9f88ded8', 'name':'Santa Lucia'},
    {'value':'92421ee1-8d52-4b9f-8ed8-c7bf3251ada0', 'name':'Santo Tomé y Príncipe'},
    {'value':'b6c5e14a-4a0d-4968-9085-59e8159ca75a', 'name':'Senegal'},
    {'value':'ecadb177-338a-42eb-98e1-5cc6f63a2949', 'name':'Serbia'},
    {'value':'88cd6d4f-4134-4460-849c-f0ce8faaeb8b', 'name':'Seychelles'},
    {'value':'72be7e45-1e71-4502-8ddc-259ce6383a57', 'name':'Sierra Leona'},
    {'value':'a5b1d1e4-2710-434c-b91f-a506c947acf4', 'name':'Singapur'},
    {'value':'846c6262-669c-4035-ac8b-ef9011c21e2c', 'name':'Siria'},
    {'value':'a137daa5-ce1e-4e57-9d11-561d7281c440', 'name':'Somalia'},
    {'value':'0871aa89-b7b1-4c44-9032-1e328b758c46', 'name':'Sri Lanka'},
    {'value':'dc2aa2cf-39ac-469f-8406-6ff3b1150ec1', 'name':'Suazilandia'},
    {'value':'1a5d25d1-8bdd-452a-aa04-b643b97d1f17', 'name':'Sudáfrica'},
    {'value':'c824bb38-30a4-4f0a-843f-ca9601d6214e', 'name':'Sudán'},
    {'value':'1702e34c-29ae-4abb-8579-dba56f5d04c8', 'name':'Sudán del Sur'},
    {'value':'011527bb-5fa4-4841-bd5f-6a0f267b85e2', 'name':'Suecia'},
    {'value':'8d966052-7790-4a76-92d8-74d2990d5bb6', 'name':'Suiza'},
    {'value':'7f0b903a-e9f7-4bc4-8a3f-46810f87e5f8', 'name':'Surinam'},
    {'value':'b2843342-ff67-4662-9332-b090054a1601', 'name':'Svalbard y Jan Mayen'},
    {'value':'88ee0d20-212d-4ef5-b469-849c1ab394bc', 'name':'Tailandia'},
    {'value':'7ce68a6b-fe73-4f40-a370-e44e8b189288', 'name':'Taiwán'},
    {'value':'7b1dfd6d-5f1b-4986-8edb-ae879bb2863c', 'name':'Tanzania'},
    {'value':'1f7eac80-5aaa-4f8e-baa9-6d692e75892a', 'name':'Tayikistán'},
    {'value':'a235a098-5e32-4837-a3fe-f56a5f055cb2', 'name':'Territorio Británico del Océano Índico'},
    {'value':'aa596e69-6990-4714-8c67-38f7204862e6', 'name':'Territorios Franceses del Sur'},
    {'value':'03a41b72-9492-48f6-87cf-268c93b39865', 'name':'Timor Oriental'},
    {'value':'c6ea887d-da91-4c5d-8e38-071a97e7f755', 'name':'Togo'},
    {'value':'073e4186-d371-4148-9a4f-4eab333ae9fc', 'name':'Tokelau'},
    {'value':'c5b2eb52-cbe1-4b68-8aa1-ac42760a3629', 'name':'Tonga'},
    {'value':'d5a3fd0b-dbdf-4f6d-81fd-2dce86d5d897', 'name':'Trinidad y Tobago'},
    {'value':'406b4fbe-5026-4d8d-8720-bc0ef586b807', 'name':'Túnez'},
    {'value':'41505717-250f-425a-81a9-4c6a3e99bbe4', 'name':'Turkmenistán'},
    {'value':'2f12a8fe-be56-42cf-8cb4-fa1024422359', 'name':'Turquía'},
    {'value':'19aeccf9-2a65-4e12-a33b-e17cc08c5004', 'name':'Tuvalu'},
    {'value':'f2e55ea7-483b-4064-9e83-8899acf0d30f', 'name':'Ucrania'},
    {'value':'e137b280-75c8-4744-a950-790bb8f06dc8', 'name':'Uganda'},
    {'value':'dd031b78-37f6-492c-b8de-59ea24dfce0b', 'name':'Uruguay'},
    {'value':'fd5acc3f-b0a8-4645-bb60-ce8e6de6eefc', 'name':'Uzbekistán'},
    {'value':'7d3e420e-1ce7-403a-9049-c9934b5821dc', 'name':'Vanuatu'},
    {'value':'79bd88fd-2b5a-4f8d-8a72-569222378694', 'name':'Venezuela'},
    {'value':'3166a52d-b5d3-4ed6-92c5-1885ecbd0eab', 'name':'Vietnam'},
    {'value':'9d033b38-0fc6-4609-a16a-5fb91f682d64', 'name':'Wallis y Futuna'},
    {'value':'c8eaddf9-337d-46c2-b626-28cf5331d388', 'name':'Yemen'},
    {'value':'fe76cf8e-10a2-49bb-9731-04ee666e4336', 'name':'Yibuti'},
    {'value':'fd5d8f7f-c132-45e2-be65-4824c89b4a25', 'name':'Zambia'},
    {'value':'1014c7d0-eedf-429c-9b80-9b6143a1a2f1', 'name':'Zimbabue'},
]


export const States = [
    {'value':'6e8466f1-a8fd-4fb3-aa6a-1ae6fa401eb2', 'name':'Aguascalientes'},
    {'value':'d6874852-8c2b-4b70-9697-3d1d010bb893', 'name':'Baja California'},
    {'value':'0aa820fe-524a-469b-bd51-1b95b3bc389c', 'name':'Baja California Sur'},
    {'value':'83532994-0483-408c-b9ed-d99eff30dd33', 'name':'Campeche'},
    {'value':'f89b7995-3e11-4fb0-9656-56117669aac6', 'name':'Chiapas'},
    {'value':'92a24803-93fe-41de-bd8f-de185d745434', 'name':'Ciudad de México'},
    {'value':'0d414c80-567a-4cbf-971a-f876143a1d60', 'name':'Chihuahua'},
    {'value':'7736d672-ff02-40f6-9878-060c613380bc', 'name':'Coahuila de Zaragoza'},
    {'value':'79c86d1d-3909-4049-a0ad-8bf7680abac1', 'name':'Colima'},
    {'value':'55ca178b-3910-41c0-87e8-f36583807e86', 'name':'Durango'},
    {'value':'d5925b85-3b8f-4f99-9815-72c90173b9aa', 'name':'Guanajuato'},
    {'value':'46f2a632-bd9d-4899-9fbf-d7e79df04b3c', 'name':'Guerrero'},
    {'value':'5f5da9a6-7784-401b-b63b-eed3c148a4c9', 'name':'Hidalgo'},
    {'value':'9da01474-24bf-4da7-84b1-fa7b40d64a92', 'name':'Jalisco'},
    {'value':'5ad3d611-b7a9-4f2f-a325-cf6961d62219', 'name':'México'},
    {'value':'995c0996-e49b-4dcd-938b-67220c1a6aed', 'name':'Michoacán'},
    {'value':'fd2d8c74-b85a-42a1-93ca-78e8fc7089b3', 'name':'Morelos'},
    {'value':'cf7d9518-442e-45cf-a0db-9e2c993dc86d', 'name':'Nayarit'},
    {'value':'3d33f140-788a-419f-a7ac-a17f596cb4cf', 'name':'Nuevo León'},
    {'value':'8827961e-05a9-4eb0-9e1c-f76a81dcae55', 'name':'Oaxaca'},
    {'value':'ff1aee93-8c69-4a46-8962-b0e9730d10de', 'name':'Puebla'},
    {'value':'93561a79-f407-4210-885d-73197c45556f', 'name':'Querétaro'},
    {'value':'8e8d9541-a15a-44fe-9204-d313eb82f9d2', 'name':'Quintana Roo'},
    {'value':'dbc726a7-206e-48ec-bc30-60a713020f7c', 'name':'San Luis Potosí'},
    {'value':'0e96653f-8560-4ae8-b737-4966ccc732d8', 'name':'Sinaloa'},
    {'value':'eec6b83f-a31f-4a8e-b76b-426a94fd022d', 'name':'Sonora'},
    {'value':'41c562c9-165d-47d0-8bf3-c53e0e9de57c', 'name':'Tabasco'},
    {'value':'4f10247d-9510-48c1-a76b-072a61c6a333', 'name':'Tamaulipas'},
    {'value':'ef661ad6-5364-4aff-83ec-ea050844f649', 'name':'Tlaxcala'},
    {'value':'8ca06254-0187-40f3-ba88-cb81982deff3', 'name':'Veracruz'},
    {'value':'d2036942-999a-4c26-b1b6-9929641fba09', 'name':'Yucatán'},
    {'value':'a277e5b0-119d-4933-b4d0-258dbbf0bcad', 'name':'Zacatecas'},
];

export const Sex = [
    {'value':1, 'name':'Hombre'},
    {'value':2, 'name':'Mujer'}
]