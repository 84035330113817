import styled from 'styled-components';
import { ReactComponent as BurgerMenu } from 'assets/burger-menu.svg'

export const UserInfoContainer = styled.div`
    display: grid;
    grid-gap: 18px;
    grid-auto-flow: column;
    align-items: center;

    @media (min-width: 769px)  {
        margin-right: 32px;
        grid-gap: 10px;
    }
`;

export const Menu = styled(BurgerMenu)`
    width: 24px;
    height: 24px;

    @media (min-width: 769px)  {
        display: none;
    }
`;

export const MenuButton = styled.button`
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0px;
`;