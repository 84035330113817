import React from 'react'
import useAxiosPublicServingInstance from './useAxiosPublicServingInstance';
import useAxiosPublicAdminInstance from './useAxiosPublicAdminInstance';

export const useCountries = () => {
    const [{data, error, loading}, getCountries] = useAxiosPublicServingInstance({
        url: `/v1/public/country/list?size=2000`,
        method: 'GET'
    }, {manual: true});

    return [data, error, loading, getCountries];
}

export function useCityStates(uuid) {
    const [{data, error, loading}, getCityStates] = useAxiosPublicServingInstance({
        url: `/v1/public/state/list/country/${uuid}?size=50`,
        method: 'GET'
    }, {manual: true});

    return [data, error, loading, getCityStates];
}

export const useConfirmEmail = () => {
    const [{data, error, loading}, confirmEmail] = useAxiosPublicAdminInstance({
        url: `/v1/public/user/confirm`,
        method: 'POST'
    }, {manual: true});

    const handleConfirmEmail = React.useCallback(async (confirm) => {
        try{
            const response = await confirmEmail({data:confirm.confirm});
            return response;
        } catch (e) {
            console.log(e);
        }
    }, [confirmEmail]);

    return {data, error, loading, handleConfirmEmail};
}

export const useRecoverPassword = () => {
    const [{data, error, loading}, recoverPassword] = useAxiosPublicAdminInstance({
        method: 'POST'
    }, {manual: true});

    const handleRecoverPassword = React.useCallback(async (email) => {
        try{
            const response = await recoverPassword({url:'/v1/public/mail/reset-password/'+ email});
            return response;
        } catch (e) {
            console.log(e);
        }
    }, [recoverPassword]);

    return {data, error, loading, handleRecoverPassword};
}

export const useResetPassword = () => {
    const [{data, error, loading}, resetPassword] = useAxiosPublicAdminInstance({
        url: `/v1/public/user/reset-password/`,
        method: 'PUT'
    }, {manual: true});

    const handleResetPassword = React.useCallback(async (resetData) => {
        try{
            const response = await resetPassword({data:resetData.resetData});
            return response;
        } catch (e) {
            console.log(e);
        }
    }, [resetPassword]);

    return {data, error, loading, handleResetPassword};
}