import React from 'react'
import useAxiosServingInstance from './useAxiosServingInstance';
import useAxiosAdminInstance from './useAxiosAdminInstance';

export const useInfoGame = (uuid) => {
    const [{data, error, loading}, getInfoGame] = useAxiosServingInstance({
        url: `/v1/info-game/season/${uuid}`,
        method: 'GET'
    }, {manual: true});

    return { data, error, loading, getInfoGame };
}

export const useGetCompetitions = () => {
    const [{data, error, loading}, getCompetitions] = useAxiosServingInstance({
        url: `/v1/competition/list`,
        method: 'GET'
    }, {manual: true});
    return { data, error, loading, getCompetitions };
}

export const useCompetitionByPermalink = (permalink) => {
    const [{data, error, loading}, handleCompetitionsByPermalink] = useAxiosServingInstance({
        url: `/v1/competition/permalink/${permalink}?es=true`,
        method: 'GET'
    }, {manual: true});

    const getCompetitionsByPermalink = React.useCallback(async (permalink) => {
        try {
            const params = (permalink !== undefined) ? { url: `/v1/competition/permalink/${permalink}?es=true`} : {}
            const response = await handleCompetitionsByPermalink(params);
            return response;
        } catch (error) {
            console.log(error)
        }
    }, [handleCompetitionsByPermalink]);

    return { data, error, loading, getCompetitionsByPermalink};
}

export const useCompetitionByUuid = (uuid) => {
    const [{data, error, loading}, getCompetitionByUuid] = useAxiosServingInstance({
        url: `/v1/competition/${uuid}`,
        method: 'GET'
    }, {manual: true});
    return { data, error, loading, getCompetitionByUuid};
}

export const useGetSeasonsByCompetition = () => {
    const [{data, error, loading}, handleSeasonsByCompetition] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getSeasonsByCompetition = React.useCallback(async (uuid) => {
        try {
            const response = await handleSeasonsByCompetition({ 
                url: `/v1/season/list/competition/${uuid}?statusList=1,2`
            });
            return response;
        } catch (error) {
            console.log(error)
        }
    }, [handleSeasonsByCompetition]);
    
    return { data, error, loading, getSeasonsByCompetition};
}

export const useMatchdaysByCompetition = (uuid, addSeason) => {
    const [{data, error, loading}, handleMatchdaybyCompetition] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getMatchdaybyCompetition = React.useCallback(async (uuid, addSeason) => {
        try {
            const response = await handleMatchdaybyCompetition({ 
                url: `/v1/matchday/list/competition/${uuid}${addSeason && "?fields=season"}`
            });
            return response;
        } catch (error) {
            console.log(error)
        }
    }, [handleMatchdaybyCompetition]);
    return { data, error, loading, getMatchdaybyCompetition};
}

export const useMatchesByMatchday = () => {
    const [{data, error, loading}, handleMatchesByMatchday] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getMatchesByMatchday = React.useCallback(async (uuid) => {
        return await handleMatchesByMatchday({
            url: `/v1/match/list/matchday/${uuid}`,
        })
    }, [handleMatchesByMatchday]);

    return { data, error, loading, getMatchesByMatchday};
}

export const useUserPoolByMatchday = (uuid) => {
    const [{data, error, loading}, handleUserPoolByMatchday] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getUserPoolByMatchday = React.useCallback(async (uuid) => {
        try {
            const response = await handleUserPoolByMatchday({
                url: `/v1/football-pools/matchday/${uuid}`
            })
            return response;
        } catch (e) {
            console.log(e);
        }
    }, [handleUserPoolByMatchday]);
    return { data, error, loading, getUserPoolByMatchday};
}

export const useSendUserPool = () => {
    const [{data, error, loading}, createUserPool] = useAxiosAdminInstance({
        url: `/v1/football-pools/create`,
        method: 'POST'
    }, {manual: true});

    const handleCreateUserPool = React.useCallback(async (pool) => {
        try {
            const response = await createUserPool({data: pool});
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [createUserPool]);

    return { data, error, loading, handleCreateUserPool};
}

export const useGetSeasonPlaying = () => {
    const [{ data, error, loading }, getSeasonPlaying] = useAxiosServingInstance({
        url: '/v1/season/playing/list',
        method: 'GET'
    }, { manual: true });

    return { data, error, loading, getSeasonPlaying };
}
