import PropTypes from 'prop-types';
import { ButtonContainer } from './styles';

const ButtonJF = ({ text, type, onClick, disabled, isGray}) => {
	return (
        <ButtonContainer type={type} onClick={onClick} disabled={disabled} isGray={isGray}>
            {text}
        </ButtonContainer>
    )
}

ButtonJF.propTypes = {
  isGray: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ButtonJF.defaultProps = {
  isGray: false,
  disabled:false,
  type: 'button',
  onClick: undefined,
};

export default ButtonJF