import styled from 'styled-components';
import player from 'assets/hero.svg';
import arrow from 'assets/vector.svg';

export const WhiteBackground = styled.div `
    background-color: #FFF;
`;

export const Button = styled.button `
    width: 100%;
    background: #3F9E34;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 19px;
    border: 0px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
`;

export const Container = styled.div`
    width:100%;
    padding: 0 15px;
    margin: 0 auto;
    overflow: hidden;
    display:flex;
    align-items:center;
    flex-direction:column;

    @media (min-width: 577px){
        max-width: 540px;
    }

    @media (min-width: 769px){
        max-width: 720px;
    }

    @media (min-width: 993px){
        max-width: 960px;
    }

    @media (min-width: 1201px){
        max-width: 1140px;
    }
`;

export const BlackButton = styled(Button) `
    background-color: #000;
    color: #fff;
    max-width: 310px;
    margin-top:110px;
`;

export const Cover = styled.div `
    background-image: url(${player});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;

    @media (min-width: 769px){
        > ${Container} {
            display:block;
        }
    }

    @media (orientation: portrait) and (max-width: 768px) {
        background-size: contain;
        background-position: center -50px;
        max-height: 520px;

        > ${Container} {
            position: absolute;
            left: 0px;
            right: 0px;
            padding-top: 55%;
            
        }
    }

    @media (orientation: portrait) and (max-width: 700px) {
        max-height: 480px;
    }

    @media (orientation: portrait) and (max-width: 600px) {
        max-height: 420px;
    }

    @media (orientation: portrait) and (max-width: 450px) {
        max-height: 370px;
    }
    
`;

export const CoverTxt = styled.div `
    width: 100%;
    max-width: 600px;
    margin-top: 120px;
    font-size: 72px;
    color: #3F9E34;
    font-weight: 800;
    line-height: 88px;
    
    span {
        color: #000;
    }

    @media (orientation: portrait) and (max-width: 768px) {
        width: 100%;
        text-align:center;
        font-size: 30px;
        line-height: 35px;
        margin-top: 0px;
    }

    @media (orientation: landscape) and (max-height: 400px),
        (orientation: landscape) and (max-width: 900px) {
        margin-top: 40px;
        font-size: 42px;
        line-height: 58px;
    }
`;

export const CoverButton = styled(Button) `
    width: 80%;
    max-width: 400px;
    margin:35px auto 0px;
`;

export const Arrow = styled.div `
    position: absolute;
    bottom: 100px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 65px;
    height: 65px;   
    background-image: url(${arrow});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
    cursor: pointer;

    @media (max-width: 768px) {
        display:none;
    }

    @media (orientation: landscape) and (max-height: 400px),
        (orientation: landscape) and (max-width: 900px) {
        bottom: 20px;
    }
`;

export const RulesContainer = styled.div `
    background-color: #fff;
    padding: 80px 0px;

    > ${Container} {
        justify-content: center;
    }
`;

export const InsideContainer = styled.div `
    justify-content: space-between;
    display:flex;

    @media (max-width: 768px) {
        flex-direction:column;
    }
`;

export const TitleSection = styled.div `
    font-size: 64px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        font-size: 40px;
        line-height: 45px;
    }
`;

export const SubtitleSection = styled.div `
    font-size: 36px;
    line-height: 37px;
    text-align: center;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 20px;
    }
`;

export const RulesImg = styled.div `
    margin-bottom: 20px;
    text-align: center;

    img {
        max-width: 100%;
        max-height: 300px;
        margin:0 auto;
    }
`;

export const TitleRulesItem = styled.div `
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
`;

export const TxtRulesItem = styled.div `
    font-size: 18px;
    text-align: center;
`;

export const RewardContainer = styled.div `
    background-color: #fff;
    padding: 80px 0px;

    @media (min-width: 501px) {
        ${InsideContainer} {
            flex-direction: row;
        }
    }
`;

export const RewardItemContainer = styled.div `
    width:50%;
    align-items: center;
    justify-content: center;
    display:flex;
    flex-direction:column;

    @media (max-width: 500px) {
        width:100%
    }
`;

export const RewardsImg = styled.div `
    width:50%;
    text-align:center;

    img {
        max-width: 100%;
        max-height: 100%;
        margin:0 auto;
    }

    @media (max-width: 500px) {
        width:100%
    }
`;

export const RewardsTxt = styled.div `
    font-size: 18px;
    font-weight: 500;

    @media (max-width: 500px) {
        text-align: center;
    }
`;