import React from 'react';

import {
    Footer,
    FooterContainer,
    FooterExternalLink,
    FooterLink,
    FooterLogo,
    SocialContainer,
    LinksContainer,
    LogoContainer,
} from './styles';

import FooterFacebook from 'assets/facebook.png';
import FooterInstagram from 'assets/instagram.png';
import FooterTwitter from 'assets/twitter.png';
import FooterTiktok from 'assets/tiktok.png';
import FooterEmail from 'assets/email.png';

import * as Routes from 'constants/Routes';

const PublicFooter = ({handleRegister, handleLogin}) => {

    return (
        <Footer>
            <FooterContainer>
                <SocialContainer>
                    <FooterExternalLink href="https://www.facebook.com/juanfutbol.mx">
                        <img src={FooterFacebook} alt="facebook" />
                    </FooterExternalLink>
                    <FooterExternalLink href="https://www.instagram.com/juanfutbol">
                        <img src={FooterInstagram} alt="instagram" />
                    </FooterExternalLink>
                    <FooterExternalLink href="https://www.twitter.com/juanfutbol">
                        <img src={FooterTwitter} alt="twitter" />
                    </FooterExternalLink>
                    <FooterExternalLink href="https://www.tiktok.com/@juanfutbol">
                        <img src={FooterTiktok} alt="comment" />
                    </FooterExternalLink>
                    <FooterExternalLink href="mailto:hey@juanfutbol.com">
                        <img src={FooterEmail} alt="email" />
                    </FooterExternalLink>
                </SocialContainer>
                <LinksContainer>
                    {/*<FooterLink href="">Contáctanos</FooterLink>*/}
                    <FooterLink to={Routes.FAQ}>Preguntas frecuentes</FooterLink>
                    <FooterLink to={Routes.LEGALS}>Legales</FooterLink>
                </LinksContainer>
                <LogoContainer>
                    <FooterLogo></FooterLogo>
                </LogoContainer>
            </FooterContainer>
        </Footer>
    );
};

export default PublicFooter;