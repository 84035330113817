import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

export const MaterialProvider = props => {
    const theme = 
        createMuiTheme({
        palette: {
            primary: {
                main: '#3F9E34',
            },
            secondary: {
                main: '#f50057'
            }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}