import PropTypes from 'prop-types';
import { 
    InputAdornment,
    FormControl,
    IconButton
} from '@material-ui/core';

import { RoundedFilledInput } from './styles';

import copy from 'copy-to-clipboard';

const ReadInput = ({where, name, value, placeholder, Icon, inputRef}) => {
    const handleCopyToClipboard = (e) => {
        copy(value);
    };

    return (
        <FormControl variant="filled" hiddenLabel>
            <RoundedFilledInput
                value={value}
                type="text"
                endAdornment={(Icon) &&
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleCopyToClipboard}
                            edge="end">
                                <Icon fill="#3F9E34" />
                        </IconButton>
                        
                    </InputAdornment>
                }
                label={placeholder}
                inputRef={inputRef}
                inputProps={{
                    name: `${name}`,
                    id: `input-${where}-${name}`,
                }}
                margin="dense"
                disableUnderline
                readOnly
            /> 
        </FormControl>
    );
 }

ReadInput.propTypes = {
    where: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Icon: PropTypes.element,
    inputRef: PropTypes.oneOfType([ PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

ReadInput.defaultProps = {
    where: "unique",
    name: "",
};

 export default ReadInput;