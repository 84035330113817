import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as RectangleSVG } from 'assets/rectangle.svg'

export const HeaderContainer = styled.div`
    height: 100%;
    min-height:76px;
    background: #ffffff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;

    @media (min-width: 769px)  {
        background: linear-gradient(90.61deg, rgba(224, 225, 223, 0.72) 3.26%, rgba(224, 225, 223, 0.1728) 100%);
        padding: 0px;
        min-height:120px;
    }
`;

export const LogoContainer = styled(Link)`
    width: 120px;
    height: 20px;

    @media (min-width: 769px)  {
        display: none;
    }
`;

export const BackgroundTitle = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    display: none;

    @media (min-width: 769px)  {
        display: block;
    }
`;

export const Title = styled.span`
    position: absolute;
    top: 15px;
    left: 40px;
    z-index: -1;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-size: 72px;
    line-height: 88px;
    color: rgba(255, 255, 255, 0.32);
    text-transform: uppercase;
    word-break: break-all;
`;

export const GreenTitle = styled.span`
    position: relative;
    font-family: Montserrat;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 44px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #3F9E34;
    margin-left: 32px;
    margin-top: 20px;
    text-transform: uppercase;
    display: none;

    @media (min-width: 769px)  {
        display: block;
    }
`;

export const Rectangle = styled(RectangleSVG)`
    width: 100px;
    height: 120px;
`