import React from 'react'
import useAxiosServingInstance from './useAxiosServingInstance';

export const useRankingBySeason = () => {
    const [{data, error, loading}, handleRankingList] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getRankingBySeason = React.useCallback(async (seasonUuid) => {
        try{
            const response = await handleRankingList({
                url: `/v1/ranking/list/season/${seasonUuid}`
            });
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [handleRankingList]);

    return {data, error, loading, getRankingBySeason};
}

export const useRankingBySeasonAndMatchday = (matchdayUuid, seasonUuid) => {
    const [{ data, error, loading }, getRankingBySeasonAndMatchday ] = useAxiosServingInstance({
        url: `/v1/ranking/list/season/${seasonUuid}/matchday/${matchdayUuid}`,
        method: 'GET'
    }, {manual: true})
    
    return { data, error, loading, getRankingBySeasonAndMatchday };
}

export const useGroupRankingBySeason = (groupUuid, seasonUuid) => {
    const [{ data, error, loading }, getGroupRankingBySeason ] = useAxiosServingInstance({
        url: `/v1/ranking/list/season/${seasonUuid}/group/${groupUuid}`,
        method: 'GET'
    }, {manual: true})
    
    return { data, error, loading, getGroupRankingBySeason };
}

export const useGroupRankingBySeasonAndMatchday = (groupUuid, seasonUuid, matchdayUuid) => {
    const [{ data, error, loading }, getGroupRankingBySeasonAndMatchday ] = useAxiosServingInstance({
        url: `/v1/ranking/list/season/${seasonUuid}/matchday/${matchdayUuid}/group/${groupUuid}`,
        method: 'GET'
    }, {manual: true})
    
    return { data, error, loading, getGroupRankingBySeasonAndMatchday };
}

