import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    useUserByCookie
} from 'services/Private/useUserService'
import * as Cookie from 'services/CookieService'; 
import { CookieName } from 'constants/Constants';

export const UserContext = createContext();

export const UserProvider = props => {
    const [user, setUser] = useState(null);
    const { getUser } = useUserByCookie(); 
    const [cookie, setCookie] = useState(
        () => Cookie.get(CookieName)
    );

    useEffect(() => {
        if(cookie || props.isMock){
            getUser()
                .then(response => {
                    setUser(response?.data?.data);
                })
                .catch(e => console.log(e));
        }
    }, [cookie, getUser, props.isMock]);

    return (
        <UserContext.Provider value={{user, cookie, setUser, setCookie}} >
            {props.children}
        </UserContext.Provider>
    )
}

UserProvider.propTypes = {
  isMock: PropTypes.bool,
};

UserProvider.defaultProps = {
  isMock: false,
};
