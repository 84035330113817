import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.div`
    height: 100%;

    background: #ffffff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    min-height: 76px;

    @media (min-width: 769px)  {
        padding: 0px 65px;
        min-height: 98px;
    }
`;

export const InteractionPanel = styled.div`
    display: grid;
    grid-gap: 42px;
    grid-auto-flow: column;
    align-items: center;

    @media (max-width: 768px) {
        grid-gap: 0px;
    }
`;

export const LogoContainer = styled(Link)`
    width: 120px;
    height: 20px;

    @media (min-width: 769px)  {
        width: 160px;
        height: 26px;
    }

    img {
        width:100%;
    }
`;

export const RegisterLink = styled.div `
    text-align: center;
    color: #3F9E34;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor:pointer;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Button = styled.button `
    background: #3F9E34;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 19px;
    border: 0px;
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    width: 230px;

    @media (max-width: 768px) {
        width: 187px;
    }

    @media (max-width: 370px) {
        width: 100%;
    }
`;