import styled from 'styled-components';

export const ProfileContainer = styled.div`
    position: relative;
`;

export const VerticalListContainer = styled.div`
    max-width:570px;
    margin:0px auto;
    width: 100%;
    padding: 20px 16px;
    display: grid;
    flex-direction: column;
    grid-gap: 16px;
    align-items: center;
    text-align:center;
    margin-bottom: 16px;
`;

export const HorizontalButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    grid-gap: 16px;
    align-items: center;
    text-align:center;
    justify-content: flex-end;

    button {
        max-width:200px;
        font-size: 16px;

        @media (max-width: 420px)  {
            font-size: 14px;
            max-width:50%;
        }
    }
`;

export const TitleProfile = styled.div`
    width: 100%;
    height: 36px;
    border-left: 3px solid #3F9E34;
    padding: 0px 20px;
    color: #3F9E34;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
`;

export const Separator = styled.div`
    width: 100%;
    height: ${props => `${props.height}px`};
`;

export const WhenNoHeader = styled.div`
    @media (min-width: 769px)  {
        display: none;
    }
`;

export const FormContainer = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 16px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ErrorParagraph = styled.p`
    color: #DA2222;
    margin: 0px;
`;