import styled from 'styled-components';

export const LegalsContainer = styled.div`
    width: 92%;
    position: relative;
    height: auto;
    margin: 4%;
`;

export const LegalGroup = styled.div`

`;

export const Legal = styled.div`
    width: 100%;
    min-height:80px;
    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
`;

export const Description = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;

    color: #000000;
    margin-bottom:20px;
`;  

export const LegalsMain = styled.div`
    text-align: left;
`;
