import PropTypes from 'prop-types';
import { useState } from 'react';
import { Controller } from "react-hook-form";
import { 
    InputAdornment,
    FormControl,
    OutlinedInput,
    InputLabel,
    IconButton,
    Select
} from '@material-ui/core';

 const Input = ({where, name, value, placeholder, type, control, required, options, Icon, inputRef, onChange}) => {
    const [ showPassword, setShowPassword ] = useState(false);
    const [ isFocus, setIsFocus ] = useState(false);
    const [ focusDate, setFocusDate ] = useState(false);
    
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleFocus = () => {
        setIsFocus(!isFocus);
        (type === 'date') && setFocusDate(!focusDate);
    };
    const handleType = () => {
        if ( type === 'password') {
            return ( showPassword ) ? 'text' : 'password';
        }

        if ( type === 'date') {
            return ( focusDate ) ? 'date' : 'text';
        }

        return type;
    }

    const getInputComponent = (field = {}) => {
        return (
            <FormControl variant="outlined">
                <InputLabel htmlFor={`input-${where}-${name}`} required={required}>{placeholder}</InputLabel>
                {type === 'select'? 
                    <Select
                        {...field}
                        native
                        placeholder={placeholder}
                        label={placeholder}
                        onChange={(value) => { if(field.onChange){field.onChange(value)} if(onChange){onChange(value)} }}
                        inputRef={inputRef}
                        inputProps={{
                            name: `${name}`,
                            id: `input-${where}-${name}`,
                        }}>
                            <option value="" />
                            { (options) &&
                                options.map(
                                    option => option instanceof Object ? 
                                        <option value={option.value} key={option.value}>{option.name}</option>
                                    :
                                        <option value={option} key={option}>{option}</option>
                                )
                            }
                    </Select>
                :
                    <OutlinedInput
                        {...field} 
                        placeholder={placeholder}
                        type={handleType()}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                        endAdornment={(Icon) &&
                            <InputAdornment position="end">
                                {type === 'password' ? 
                                    <IconButton
                                        onClick={handleShowPassword}
                                        edge="end">
                                            <Icon fill={isFocus ? '#3F9E34' : '#646761'} />
                                    </IconButton>
                                :
                                    <Icon fill={isFocus ? '#3F9E34' : '#646761'} />
                                }
                            </InputAdornment>
                        }
                        label={placeholder}
                        inputRef={inputRef}
                        inputProps={{
                            name: `${name}`,
                            id: `input-${where}-${name}`,
                        }}
                    /> 
                }
            </FormControl>
        );
    }

    return (
        <>
            {(control) ?
                <Controller
                    name={name}
                    control={control}
                    defaultValue={(value)?value:''}
                    rules={{ required: required }}
                    render={({ field }) => getInputComponent(field)} />
            :
                getInputComponent({ "defaultValue":value })
            }
        </>
    );
 }

 Input.propTypes = {
    where: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'select', 'date']),
    control: PropTypes.object,
    required: PropTypes.bool,
    options : PropTypes.arrayOf([PropTypes.string, PropTypes.number, PropTypes.object]),
    Icon: PropTypes.element,
    inputRef: PropTypes.oneOfType([ PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
    onChange: PropTypes.func,
};

Input.defaultProps = {
    where: "unique",
    name: "",
    type: "text",
    placeholder: "",
    required: false,
};

 export default Input;