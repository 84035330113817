import styled from 'styled-components';

export const PriceContainer = styled.div`
    position: relative;
    width: 80%;
    border-radius: 12px;
    background-color: #ffffff;
    overflow: hidden;
    margin: 0px 10%;

    display: grid;

    &:after {
        content: " ";
        display: block;
        padding-bottom: 84%;
    }

    @media (min-width: 769px)  {
        width: 90%;
        margin: 0px 5%;

        &:after {
            padding-bottom: 30%;
        }
    }
`;

export const PriceDataContainer = styled.div`
    position: absolute;
    width: 100%;
    height:100%;
    font-family: 'Montserrat';
    font-style: normal;
    line-height: 17px;
    letter-spacing: 0.175714px;
    color: #000000;
    text-align: left;

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 6fr 1fr 1fr;
    grid-template-areas: 'image''type''description';
    grid-gap: 6px;

    @media (min-width: 769px)  {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 5fr 2fr 2fr;
        grid-template-areas: 'image . ''image type''image description';
    }
`;

export const HeaderImage = styled.div`
    background-image: url(${props => props.image});
    width: 100%;

    background-size: cover;
    background-position: center center;
    position: relative;
    grid-area: image;

    @media (min-width: 769px)  {
        margin: 5%;
        width: 90%;
        border-radius: 12px;
    }
`;

export const PriceType = styled.div`
    font-weight: 600;
    font-size: 4vw;
    line-height: 4.1vw;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #3F9E34;
    grid-area: type;
    margin: 0 5%;

    @media (min-width: 769px)  {
        margin: 0 2.5% 0 0;
        font-size: 2vw;
        line-height: 2.1vw;
    }
`;

export const PriceDescription = styled.div`
    font-weight: bold;
    font-size: 3vw;
    line-height: 3.1vw;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;

    color: #252733;
    grid-area: description;
    margin: 0 5% 5% 5%;
    

    @media (min-width: 769px)  {
        margin: 0 2.5% 2.5% 0;
        font-size: 3vw;
        line-height: 3.1vw;
    }
`;