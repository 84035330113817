import styled from 'styled-components';

export const GroupsContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
`;

export const GroupsListContainer = styled.div`
    position: relative;
    overflow: auto;
    height: 100%;
`;

export const TitleGroup = styled.div`
    width: 100%;
    height: 36px;
    border-left: 3px solid #3F9E34;
    padding: 0px 20px;
    color: #3F9E34;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
`;

export const HorizontalListContainer = styled.div`
    width: 100%;
    padding-left: 16px;
    overflow: auto;
    white-space: nowrap;

    a {
        display: inline-block;
        margin-right: 16px;
    }
`;

export const VerticalListContainer = styled.div`
    width: 100%;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
`;


export const Separator = styled.div`
    width: 100%;
    height: ${props => `${props.height}px`};
`;

export const BlurContent = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: 0.3;
    top: 0px;
    left: 0px;
    z-index: 1050;
`;

export const DrawerContainer = styled.div`
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 1051;
    bottom: -10px;
    display: flex;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    transform: translateY(${props => props.showDrawer ? '0vh':'100vh'});
    right:0px;

    @media (min-width: 769px)  {
        position: absolute;
        width: auto;
        bottom: auto;
        top: 45px;        
        right: 20px;
        display: ${props => props.showDrawer ? 'block':'none'};
    }

    @media (max-width: 768px)  {
        > div > div{
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
`;