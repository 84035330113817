import PropTypes from 'prop-types';
import { Card , ButtonJF, Input } from 'components';
import { 
    Title, 
    FormContainer, 
    RecoverLink, 
    RegisterLink, 
    CountParagraph, 
    BottomContainer 
} from './styles';
import { useForm } from "react-hook-form";

import { ReactComponent as Pass } from 'assets/pass.svg';
import { ReactComponent as Email } from 'assets/arroba.svg';
import useUser from 'hooks/useUser';

const LoginCard = ({ handleRegister, handleRecover }) => {
    const { control, handleSubmit } = useForm();

    const { login } = useUser();
    
    const onSubmit = async formData => {
        login({...formData});
    };

    return (
        <Card>
            <Title>Inicia sesión</Title>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
                <Input 
                    where='login'
                    name='email'
                    placeholder='Correo electrónico'
                    type='email'
                    control={control}
                    Icon={Email}
                />
                <Input 
                    where='login'
                    name='password'
                    placeholder='Contraseña'
                    type='password'
                    control={control}    
                    Icon={Pass}
                />  
                <ButtonJF type='submit' text='¡Juega ya!'/>
            </FormContainer>
            <RecoverLink onClick={ handleRecover }>Recuperar contraseña</RecoverLink>
            <BottomContainer>
                <CountParagraph>¿No tienes cuenta?</CountParagraph>
                {' '}
                <RegisterLink onClick={ handleRegister } >Regístrate</RegisterLink>
            </BottomContainer>
        </Card>
    );
}

LoginCard.propTypes = {
  handleRegister: PropTypes.func,
  handleRecover: PropTypes.func,
}

export default LoginCard;