import styled from 'styled-components';
import sendMobile from '../../assets/send-mobile.svg';
import sendDesk from '../../assets/send-desk.svg';

export const SendButtonDesk = styled.button `
    width: 120px;
    height: 120px;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 999;
    background-image: url(${sendDesk});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-color: transparent;
    border: none;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const SendButtonMobile = styled.button `
    width: 220px;
    height: 100px;
    position: fixed;
    right: 0;
    left: 0;
    margin: auto;
    bottom: 50px;
    z-index: 999;
    display: none;
    background-image: url(${sendMobile});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-color: transparent;
    border: none;
    
    @media (max-width: 768px) {
        display: block;
    }
`;

export const MatchdayForm  = styled.form `
    padding-bottom:150px;
`;