import styled from 'styled-components';
import backLigaMx from '../../assets/back-ligamx.png';

import { Skeleton } from '@material-ui/lab';

export const CompetitionTopContainer = styled.div `
    margin-bottom: 20px;
`;

export const CompetitionBackSkeleton = styled(Skeleton)`
    &.MuiSkeleton-root{
        height: 400px;
    }
`;

export const CompetitionBack = styled.div `
    background-image: url(${backLigaMx});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const Sponsor = styled.div `
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 340px;
    height: 105px;
    img {
        width: 100%;
        height: auto;
    }
   @media (max-width:768px) {
       width: 300px;
   }
`;

export const CompetitionContainer = styled.div `
    position: absolute;
    bottom: 0px;
    margin: auto;
    height: 120px;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 400px;
    overflow: hidden;
`;

export const ArrowBefore = styled.div `
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 0px;
    cursor: pointer;
    @media (max-width: 768px) {
        left: -40px;
    }
`;

export const ArrowAfter = styled.div `
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 0px;
    cursor: pointer;
    @media (max-width: 768px) {
        right: -40px;
    }
`;

export const CompetitionName = styled.div `
    width: 300px;
    height: 120px;
    margin: auto;
    color: #fff;
    text-align: center;
    line-height: 144px;
    font-size: 19px;
    font-weight: 700;
    img {
        width: 100%;
        height: 100%;
    }

    @media (max-width:400px) {
       width: 150px;
    }
`;

export const BottomBarsSkeleton = styled(Skeleton)`
    &.MuiSkeleton-root{
        height: 98px;
    }
`;

export const PointsContainer = styled.div `
    width: 100%;
    height: 50px;
    text-align: center;
    color: #646761;
    font-size: 16px;
    line-height: 45px;
    background-color: #fff;
    span {
        color: #3F9E34;
        font-weight: 700;
    }
`;