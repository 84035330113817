import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    text-align: center;
    color: #646761;
    margin-top: 0px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const FormContainer = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 16px;
`;

export const AgreementContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

export const AgreementParagraph = styled.p`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: #000000;
`;

export const TermsAndConditions = styled(Link)`
    font-weight: 500;
    color: #000000;
    text-decoration: underline;
`;

export const TryLoginContainer = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #646761;
    text-align: center;
`;

export const TryLoginLink = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #3F9E34;
    text-decoration: underline;
    cursor:pointer;
`;

export const ErrorParagraph = styled.p`
    color: #DA2222;
    margin: 0px;
`;

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
`;

export const ThankyouParagraph = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
`
export const SendEmailParagraph = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
`;
