import React from 'react';

import {
    Container,
    Separator,
    TitleFaq,
    FaqContainer,
    WhenNoHeader
} from './styles';

import {
    QuestionsPanel
} from 'components';

const Faq = () => {

    const questions = [
        { 
            "question" : "¿Qué es Quiniela juanfutbol?",
            "answer" : "Es una quiniela donde podrás jugar y ganar premios, además de invitar a tus amigos a torneos y competir con ellos."
        },{ 
            "question" : "¿Tiene costo?",
            "answer" : "Participar en la Quiniela juanfutbol es totalmente gratuito. Envía tus pronósticos antes de cada jornada sin costo alguno."
        },{ 
            "question" : "¿Existen restricciones para obtener los premios?",
            "answer" : "Si el usuario hace mal uso de la aplicación alterando de alguna forma los datos, no podrá obtener premios. Los únicos ganadores son los que mayor puntaje alcancen jugando limpio."
        },{ 
            "question" : "¿Debo ser mayor de edad para participar?",
            "answer" : "No tienes que ser mayor de edad para participar. ¡Eres bienvenido!"
        },{ 
            "question" : "¿Cuál es el criterio de desempate?",
            "answer" : "En caso de un empate en puntos, el ganador será el participante que haya enviado primero su quiniela"
        },{ 
            "question" : "¿Cuántos puntos me dan por partido acertado?",
            "answer" : "Por partido acertado te otorgamos 1 punto."
        },{ 
            "question" : "¿Cuántas veces puedo mandar mi quiniela?",
            "answer" : "Puedes modificar y enviar tu quiniela cuantas veces creas necesario, siempre y cuando sea previo al comienzo de cada partido. Tras el inicio de cada encuentro ya no será posible hacer cambios. Se tomará en cuenta el último pronóstico seleccionado."
        },{ 
            "question" : "¿Qué necesito para ganar?",
            "answer" : "¡Haz más puntos que los demás y juega limpio!"
        },{ 
            "question" : "¿A qué se refiere con +1pt, +3pts y +5 pts?",
            "answer" : "¡Hay partidos destacados que nadie se puede perder! Si tu pronóstico es correcto te damos +1, +3 ó +5 puntos extras."
        },{ 
            "question" : "¿Qué pasa si un partido es suspendido?",
            "answer" : "En caso que un partido sea suspendido o pospuesto será borrado de la jornada y no se tomarán en cuenta los puntos."
        }/*,{ 
            "question" : "¿Qué pasa si un partido termina en empate?",
            "answer" : "En caso que un partido termine en empate no se tomarán en cuenta los puntos para ningún participante esa semana."
        }*/
    ]
    
    return (
    	<>
    		{ (questions) && 
    			<FaqContainer>
                    <WhenNoHeader>
                        <Separator height={10} />
                        <TitleFaq>Preguntas frecuentes</TitleFaq>
                        <Separator height={10} />
                    </WhenNoHeader>
			        <Container>
                        <QuestionsPanel questions={questions}></QuestionsPanel>
			        </Container>
		        </FaqContainer>
		    }  
	    </>
    )
}

export default Faq
