import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { servingApi } from 'constants/ApiUrls';

const useAxiosPublicServingInstance = makeUseAxios({
    axios: axios.create({
        baseURL: servingApi,
        headers: {
            'Content-Type':'application/json'
        }
    })
})

export default useAxiosPublicServingInstance;