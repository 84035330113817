import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from "lodash";
import { 
    useCompetitionByPermalink,
    useMatchdaysByCompetition,
    useMatchesByMatchday,
    useUserPoolByMatchday
} from 'services/Private/useGameService';

import { 
    HeaderPool,
    Match
} from 'components';

const Matchdays = () => {
    const { permalink, matchday } = useParams();
    const { loading:loadingSeason, getCompetitionsByPermalink } = useCompetitionByPermalink(permalink);
    const { loading:loadingMatch, getMatchdaybyCompetition } = useMatchdaysByCompetition();
    const { loading: loadingDay, getMatchesByMatchday } = useMatchesByMatchday();
    const { loading: loadingUser, getUserPoolByMatchday} = useUserPoolByMatchday();
    const [ matches, setMatches ] = useState([]);
    const [ matchesUser, setMatchesUser ] = useState([]);
    const [ resultMatchdays, setResultMatchdays ] = useState({});
    const [ pointsUser, setPointsUser ] = useState([]);
    const sponsor = "samsung"

    const getInfoMatches = React.useCallback(async () => {
        try {
            if(!resultMatchdays[permalink]){
                const competitionResponse = await getCompetitionsByPermalink(permalink);
                const matchDaysResponse = await getMatchdaybyCompetition(competitionResponse.data.data.uuid, '');
                const currentMatchdayIndex = _.findIndex(matchDaysResponse.data.data, ['nextActive', true]);
                const olderMatchdays = _.slice(matchDaysResponse.data.data, 0, currentMatchdayIndex);
                
                setMatchesUser([]);
                setMatches([]);
                setResultMatchdays(resultMatchdays => {
                    let newObject = {...resultMatchdays};
                    newObject[permalink] = {
                        "matchdays": olderMatchdays,
                        "data": competitionResponse.data
                    }
                    return newObject;
                });
            } else{
                let visibleMatchday = null;
                if(matchday){
                    visibleMatchday = _.find(resultMatchdays[permalink].matchdays, ['uuid', matchday]);
                }

                if(!visibleMatchday){
                    visibleMatchday = resultMatchdays[permalink].matchdays[resultMatchdays[permalink].matchdays.length - 1];
                }
                
                const matchesByMatchdayUserResponse = await getUserPoolByMatchday(visibleMatchday.uuid);
                const matchesByMatchdayResponse = await getMatchesByMatchday(visibleMatchday.uuid);
                
                if(matchesByMatchdayUserResponse?.data?.data){
                    setMatchesUser(matchesByMatchdayUserResponse.data.data.prognosticList);
                    setPointsUser(matchesByMatchdayUserResponse.data.data.points);
                }
                
                setMatches(matchesByMatchdayResponse.data.data);
            }            
        } catch (e) {
            console.log(e);
            setMatchesUser([]);
            setMatches([]);
        }
    }, [permalink, matchday, resultMatchdays, getCompetitionsByPermalink,  getMatchdaybyCompetition, getUserPoolByMatchday, getMatchesByMatchday]);

    useEffect( () => {
        getInfoMatches();
    }, [getInfoMatches]);

    return (
        <>
            <HeaderPool 
                data={(resultMatchdays && resultMatchdays[permalink]) && resultMatchdays[permalink].data}
                sponsor={sponsor} 
                hasBottomBars
                matchdays={(resultMatchdays && resultMatchdays[permalink]) && resultMatchdays[permalink].matchdays}
                pointsUser={pointsUser}
            />
            { (!loadingSeason && !loadingMatch && !loadingDay && !loadingUser && matches.length > 0) ? 
                ( matches.map((match, index) => {
                        const userPick = _.find(matchesUser, ['matchUuid', match.uuid]);

                        return <Match 
                            key={match.uuid}
                            match={match}
                            userPick={userPick}
                            noOpacity
                        />
                    })
                )
            :
                ( [0,1,2,3,4,5,6,7].map( index => {
                        return <Match key={index} />
                    })
                )
            }           
        </>
    );
}

 
export default Matchdays;