
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { PublicLayout } from 'sections';
import { POOLS } from 'constants/Routes';

const PublicRoute = ({components: Component, ...rest}) => {
    const { user } = useContext(UserContext);
    return (
    <Route 
        {...rest} 
        
        render={ props => {
            if (!user) {
                return (
                    <PublicLayout>
                        <Component {...props} />
                    </PublicLayout>
                )
            }            
            return (
                <Redirect
                    to={{
                        pathname: POOLS,
                        state: { from: props.location }
                    }}
                />
            )
        }
    } />
)}

export default PublicRoute;