import React from 'react';
import {
    AvatarContainer,
    Picture,
    InitialsContainer,
    GoldBadgeContainer,
    SilverBadgeContainer,
    BronceBadgeContainer,
    WhiteBadgeContainer
} from './styles';

const getLetters = (firstName, lastName) => {
    return `${firstName && firstName.charAt(0)}${lastName && lastName.charAt(0)}`;
}

const TopIcon = ({ isRowIcon=false, firstName, lastName, picture, position }) => (
    <AvatarContainer>
        { picture ? 
            <Picture />
            :
            <InitialsContainer isRow={isRowIcon}>
                {getLetters(firstName, lastName)}
            </InitialsContainer>
        }
        {
            {
                '1': <GoldBadgeContainer isRow={isRowIcon}>1</GoldBadgeContainer>,
                '2': <SilverBadgeContainer isRow={isRowIcon}>2</SilverBadgeContainer>,
                '3': <BronceBadgeContainer isRow={isRowIcon}>3</BronceBadgeContainer>
            }[position] || <WhiteBadgeContainer isRow={isRowIcon}>{position}</WhiteBadgeContainer>
        }
        
    </AvatarContainer>
)

export default TopIcon;