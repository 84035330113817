import React, { useState, useEffect } from 'react';
import { GroupCard, CreateGroupCard } from 'components';
import {
    useCompetitionByPermalink,
    useGetSeasonsByCompetition
} from 'services/Private/useGameService';
import {
    usePublicGroupsBySeason,
    useUserGroupsBySeason
} from 'services/Private/useGroupService';
import {
    GroupsContainer,
    GroupsListContainer,
    TitleGroup,
    HorizontalListContainer,
    Separator,
    VerticalListContainer,
    DrawerContainer,
    BlurContent
} from './styles';
import { Link, useParams } from 'react-router-dom';

const Groups = () => {
    const { permalink } = useParams();
    const { getCompetitionsByPermalink } = useCompetitionByPermalink(permalink);
    const { getSeasonsByCompetition } = useGetSeasonsByCompetition();
    const { getPublicGroupsBySeason} = usePublicGroupsBySeason();
    const { getUserGroupsBySeason } = useUserGroupsBySeason();
    const [ publicGroups, setPublicGroups ] = useState([]);
    const [ userGroups, setUserGroups ] = useState([]);
    const [ showCreate, setShowCreate ] = useState(false);
    const [ seasonUuid, setSeasonUuid ] = useState("");

    const closeDrawers = () => {
        setShowCreate(false);
    }

    const handleCreate = () => {
        setShowCreate(true);
    }

    const getSeasons = React.useCallback(async () => {
        const competitionResponse = await getCompetitionsByPermalink();
        let seasonUuidTmp;
        if(competitionResponse.data.data.seasonUuid){
            seasonUuidTmp = competitionResponse.data.data.seasonUuid;
        }else{
            const seasonsResponse = await getSeasonsByCompetition(competitionResponse.data.data.uuid)
            seasonUuidTmp = seasonsResponse.data.data[0].uuid;
        }
        setSeasonUuid(seasonUuidTmp);

        const userGroup = await getUserGroupsBySeason(seasonUuidTmp);
        if (userGroup) {
            setUserGroups(oldArray => [...fakeGroups, ...userGroup.data.data]);
        }

        const publicGroup = await getPublicGroupsBySeason(seasonUuidTmp);
        if (publicGroup) {
            setPublicGroups(oldArray => [...fakeGroups, ...publicGroup.data.data]);
        }

    }, [getPublicGroupsBySeason, getCompetitionsByPermalink, getSeasonsByCompetition, getUserGroupsBySeason]);

    useEffect( () => {
        getSeasons();
    }, [getSeasons]);
    
    return (
        <GroupsContainer blockScroll={showCreate}>
            {(showCreate) && 
                <BlurContent onClick={ closeDrawers } />
            }
            <GroupsListContainer>
                <Separator height={10} />
                <TitleGroup onClick={handleCreate}>Mis Grupos</TitleGroup>
                <Separator height={10} />
                <HorizontalListContainer>
                    {userGroups.map(group => (
                        <Link key={group.uuid}>
                            <GroupCard 
                                name={group.name}
                                participants={23}
                                crown='Usuario'
                                media={group.mediaUrl}
                                className='items'
                            />
                        </Link>
                    ))}
                </HorizontalListContainer>
                <Separator height={30} />
                <TitleGroup>Grupos Top</TitleGroup>
                <Separator height={10} />
                <HorizontalListContainer>
                    {publicGroups.map(group => (
                        <Link key={group.uuid}>
                            <GroupCard 
                                name={group.name}
                                participants={23}
                                crown='Usuario'
                                media={group.mediaUrl}
                                className='items'
                                isTop
                            />
                        </Link>
                    ))}
                </HorizontalListContainer>
                <Separator height={30} />
                <TitleGroup>Más Grupos</TitleGroup>
                <Separator height={10} />
                <VerticalListContainer>
                    {publicGroups.map(group => (
                        <Link key={group.uuid}>
                            <GroupCard 
                                name={group.name}
                                participants={23}
                                crown='Usuario'
                                media={group.mediaUrl}
                                className='items'
                            />
                        </Link>
                    ))}
                </VerticalListContainer>
            </GroupsListContainer>
            <DrawerContainer showDrawer={showCreate}>
                <CreateGroupCard seasonUuid={seasonUuid} closeDrawers={closeDrawers}/>
            </DrawerContainer>
        </GroupsContainer>
    )
}

export default Groups;

const fakeGroups = [
    {
        "uuid": "4991528e-6df9-4768-9735-373c3b1258e1",
        "status": 1,
        "statusEnStr": "Active",
        "statusEsStr": "Activo",
        "dateCreated": "2020-07-16 19:57:26",
        "permalink": "grupo-liga-mx-juanfutboll-public",
        "name": "Grupo liga mx juanfutboll public",
        "type": 1,
        "typeEnStr": "Public",
        "typeEsStr": "Público",
        "mediaUrl": "https://e00-marca.uecdn.es/assets/multimedia/imagenes/2020/03/09/15837739214060.jpg",
        "dateModified": "2020-07-16 19:58:40",
        "userUuid": "062ffe3f-2698-4711-abd4-a499c696454b",
        "lastModifiedUserUuid": "062ffe3f-2698-4711-abd4-a499c696454b"
    },
    {
        "uuid": "a922793e-6522-4fa2-8131-f166afdcea36",
        "status": 1,
        "statusEnStr": "Active",
        "statusEsStr": "Activo",
        "dateCreated": "2020-08-06 17:30:07",
        "permalink": "blackgorilla649",
        "name": "blackgorilla649",
        "type": 1,
        "typeEnStr": "Public",
        "typeEsStr": "Público",
        "mediaUrl": "https://i.blogs.es/16d0d5/pokemon-go-aniversario/1366_2000.jpeg",
        "dateModified": "2020-08-06 17:30:07",
        "userUuid": "a843b496-8cbd-482b-a3b7-ebc138ddf2b0",
        "lastModifiedUserUuid": "062ffe3f-2698-4711-abd4-a499c696454b"
    },
    {
        "uuid": "9c774ab5-5ef6-42b5-a784-23aa61f7f04a",
        "status": 1,
        "statusEnStr": "Active",
        "statusEsStr": "Activo",
        "dateCreated": "2020-08-06 17:30:17",
        "permalink": "organicsnake660",
        "name": "organicsnake660",
        "type": 1,
        "typeEnStr": "Public",
        "typeEsStr": "Público",
        "mediaUrl": "https://as.com/meristation/imagenes/2020/10/23/noticias/1603445369_648285_1603445409_noticia_normal.jpg",
        "dateModified": "2020-08-06 17:30:17",
        "userUuid": "f56c1753-9a90-4b92-9433-bc7d2ae16b83",
        "lastModifiedUserUuid": "062ffe3f-2698-4711-abd4-a499c696454b"
    },
    {
        "uuid": "9c774ab5-5ff6-42b5-a784-23aa61f7f04a",
        "status": 1,
        "statusEnStr": "Active",
        "statusEsStr": "Activo",
        "dateCreated": "2020-08-06 17:30:17",
        "permalink": "laconchita",
        "name": "laconchita",
        "type": 1,
        "typeEnStr": "Public",
        "typeEsStr": "Público",
        "mediaUrl": "https://i.pinimg.com/originals/90/dc/d9/90dcd99965c07277a17c72999e687535.png",
        "dateModified": "2020-08-06 17:30:17",
        "userUuid": "f56c1753-9a90-4b92-9433-bc7d2ae16b83",
        "lastModifiedUserUuid": "062ffe3f-2698-4711-abd4-a499c696454b"
    },
    {
        "uuid": "9c774ab5-55f6-42b5-a784-23aa61f7f04a",
        "status": 1,
        "statusEnStr": "Active",
        "statusEsStr": "Activo",
        "dateCreated": "2020-08-06 17:30:17",
        "permalink": "vivalaindependencia",
        "name": "vivalaindependencia",
        "type": 1,
        "typeEnStr": "Public",
        "typeEsStr": "Público",
        "mediaUrl": "https://images2.alphacoders.com/531/thumb-1920-531675.jpg",
        "dateModified": "2020-08-06 17:30:17",
        "userUuid": "f56c1753-9a90-4b92-9433-bc7d2ae16b83",
        "lastModifiedUserUuid": "062ffe3f-2698-4711-abd4-a499c696454b"
    }
];