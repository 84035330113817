import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { adminApi } from 'constants/ApiUrls';
import * as Cookie from 'services/CookieService'; 
import { CookieName } from 'constants/Constants';

const axiosAdminWithInterceptor = axios.create({
    baseURL: adminApi,
    headers: {
        'Content-Type':'application/json'
    }
});

axiosAdminWithInterceptor.interceptors.request.use(
  async (config) => {
    const token = Cookie.get(CookieName);

    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const useAxiosAdminInstance = makeUseAxios({
    axios: axiosAdminWithInterceptor
})

export default useAxiosAdminInstance;