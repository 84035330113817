import styled from 'styled-components';

export const LoginContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 50px 10px 50px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
`;
