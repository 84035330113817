import styled from 'styled-components';

export const PricesContainer = styled.div`
    position: relative;
`;

export const VerticalListContainer = styled.div`
    width: 100%;
    padding: 0px 16px;
    display: grid;
    flex-direction: column;
    grid-gap: 16px;
    align-items: center;
    text-align:center;
    margin-bottom: 16px;
`;

export const TitleRanking = styled.div`
    width: 100%;
    height: 36px;
    border-left: 3px solid #3F9E34;
    padding: 0px 20px;
    color: #3F9E34;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
`;

export const Separator = styled.div`
    width: 100%;
    height: ${props => `${props.height}px`};
`;


export const SponsorLogo = styled.div`
    margin:20px;

    img {
        max-width:300px;
        width:100%;
    }

    @media (min-width: 769px)  {
        margin:40px;
        margin-bottom:20px;

        img {
            max-width:500px;
            width:100%;
        }
    }
`;

export const TitlePrices = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 4vw;
    line-height: 4.1vw;

    color: #252733;

    margin-bottom:20px;

    @media (min-width: 769px)  {
        font-size: 2vw;
        line-height: 2.1vw;
    }
`;

export const SubtitlePrices = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 5vw;
    line-height: 5.1vw;

    color: #252733;

    margin-bottom:20px;

    @media (min-width: 769px)  {
        font-size: 3vw;
        line-height: 3.1vw;
    }
`;

export const WhenNoHeader = styled.div`
    @media (min-width: 769px)  {
        display: none;
    }
`;