import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/close.svg'

import {
    GroupsIcon
} from 'theme/SVGIcons';

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #000;
    margin-top: 30px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const FormContainer = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    margin: 30px 0px 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap:16px;
`

export const TypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

export const TypeParagraph = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: #646761;
`;

export const TitleTypeParagraph = styled.div`
    font-weight: 500;
    color: #000000;
`;

export const AcceptanceContainer = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    color: #000;
    text-align: center;
    background: rgba(249, 197, 65, 0.24);
    border-radius: 8px;
    padding:5px;
`;

export const ErrorParagraph = styled.p`
    color: #DA2222;
    margin: 0px;
`;

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const ThankyouParagraph = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height:22px
    text-align: center;
    color: #3F9E34;
    margin:1em 0 0 0;
`
export const GroupNameParagraph = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    margin:0px;
`;

export const Separator = styled.hr`
    border-width:0px;
    border-bottom:1px solid #E6E7E6;
    width:100%;
    height:0px;
`;

export const SocialContainer = styled.div`
    justify-content:space-between;
    display:flex;
    max-width: 130px;
    width: 100%;
    margin: 1em 0 1em 0;
`;

export const ShareParagraph = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #646761;
    font-size: 16px;
`;

export const ExternalLink = styled.a `
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`;

export const GroupLogo = styled(GroupsIcon)`
    width: 72px;
    height: 72px;
    fill: #3F9E34;
    margin-top:20px;
`;

export const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width:90%;
    margin-bottom:20px;
`;

export const Close = styled(CloseIcon)`
    width: 14px;
    height: 14px;
    top:20px;
    right: 20px;
    position: absolute;
    cursor: pointer;
`;