import styled from 'styled-components';
import footerLogo from 'assets/juanfutbol-gris.svg';
import { Link } from 'react-router-dom';

export const Footer = styled.div `
    background-color: #000;
    padding: 80px 0px;
`;

export const FooterContainer = styled.div `
    width: 80%;
    max-width: 500px;
    margin: 0px auto;
    align-item:center;
`;

export const FooterExternalLink = styled.a `
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`;

FooterExternalLink.defaultProps = {
    target: '_blank',
    href: '#',
};

export const FooterLink = styled(Link) `
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #fff;
    margin-top: 40px;
`;

export const FooterLogo = styled.div `
    background-image: url(${footerLogo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 20px;
    margin: 50px auto 0px auto;
`;

export const SocialContainer = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const LinksContainer = styled.div `
    display: flex;
    flex-wrap: wrap;
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
`;

export const LogoContainer = styled.div `
    display: flex;
    flex-wrap: wrap;
`;