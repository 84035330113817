import React from 'react';
import {
    AvatarContainer,
    Picture,
    InitialsContainer
} from './styles';

const getLetters = (firstName, lastName) => {
    return `${firstName && firstName.charAt(0)}${lastName && lastName.charAt(0)}`;
}

const UserIcon = ({ firstName, lastName, picture }) => (
    <AvatarContainer>
        { picture ? 
            <Picture />
            :
            <InitialsContainer>
                {getLetters(firstName, lastName)}
            </InitialsContainer>
        }
    </AvatarContainer>
)

export default UserIcon;