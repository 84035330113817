import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { servingApi } from 'constants/ApiUrls';
import * as Cookie from 'services/CookieService'; 
import { CookieName } from 'constants/Constants';

const axiosServingWithInterceptor = axios.create({
    baseURL: servingApi,
    headers: {
        'Content-Type':'application/json'
    }
});

axiosServingWithInterceptor.interceptors.request.use(
  async (config) => {
    const token = Cookie.get(CookieName);

    if (token) {
      config.headers = {
         'Authorization': `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const useAxiosServingInstance = makeUseAxios({
    axios: axiosServingWithInterceptor
})

export default useAxiosServingInstance;