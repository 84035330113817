import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from "lodash";
import { 
    useCompetitionByPermalink,
    useMatchdaysByCompetition,
    useMatchesByMatchday,
    useSendUserPool,
    useUserPoolByMatchday
} from 'services/Private/useGameService';
import { SendButtonDesk, 
    SendButtonMobile,
    MatchdayForm 
} from './styles';
import { 
    HeaderPool,
    Match,
    Modal,
} from 'components';
import { useForm } from "react-hook-form";

const Matchdays = () => {
    const { permalink } = useParams();
    const { data:dataSeason, loading:loadingSeason, getCompetitionsByPermalink } = useCompetitionByPermalink(permalink);
    const { loading:loadingMatch, getMatchdaybyCompetition } = useMatchdaysByCompetition();
    const { loading: loadingDay, getMatchesByMatchday } = useMatchesByMatchday();
    const { loading: loadingPool, handleCreateUserPool } = useSendUserPool();
    const { loading: loadingUser, getUserPoolByMatchday} = useUserPoolByMatchday();
    const [ matches, setMatches ] = useState([]);
    const [ matchday, setMatchday ] = useState();
    const [ matchesUser, setMatchesUser ] = useState([]);
    const { handleSubmit, control, unregister } = useForm();
    const [ modalObject, setModalObject] = useState({"right":true, "text": "", "open":false});
    const sponsor = "samsung"

    const getInfoMatches = React.useCallback(async () => {
        try {
            const competitionResponse = await getCompetitionsByPermalink(permalink);
            // Need a better error handling
            const matchdaysResponse = await getMatchdaybyCompetition(competitionResponse.data.data.uuid, '');
            const currentMatchday = _.find(matchdaysResponse.data.data, ['nextActive', true]);
            const matchesByMatchdayUserResponse = await getUserPoolByMatchday(currentMatchday.uuid);
            const matchesByMatchdayResponse = await getMatchesByMatchday(currentMatchday.uuid);
            setMatchday(currentMatchday);
            
            if(matchesByMatchdayUserResponse?.data?.data){
                setMatchesUser(matchesByMatchdayUserResponse.data.data.prognosticList);
            }
            
            setMatches(matchesByMatchdayResponse.data.data);
        } catch (e) {
            console.log(e);
            setMatchday([]);
            setMatchesUser([]);
            setMatches([]);
        }
    }, [permalink, getCompetitionsByPermalink,  getMatchdaybyCompetition, getUserPoolByMatchday, getMatchesByMatchday]);

    useEffect( () => {
        getInfoMatches();
    }, [getInfoMatches]);

    const onSubmit = async formData => {
        const objectArray = Object.entries(formData);
        const poolData = [];

        objectArray.forEach(([key, value]) => {
            if(value){
                poolData.push({
                    matchUuid: key,
                    result: value
                })
            }else if (matchesUser?.length) {
                const userLastPick = _.find(matchesUser, ['matchUuid', key]);

                if(userLastPick){
                    poolData.push({
                        matchUuid: key,
                        result: userLastPick.result
                    })
                }
            }
        });

        if(poolData.length === 0){
            setModalObject(object => {
                return {
                    ...object,
                    open: true,
                    right: false,
                    text: "Realiza un cambio en tu quiniela para que guardemos los cambios."
                }
            });
        }else{

            const bodyPayload = {
                matchdayUuid: matchday.uuid,
                status: 1,
                prognosticList: poolData
            }

            const responsePool = await handleCreateUserPool(bodyPayload);

            if (responsePool?.status === 200) {
                setModalObject(object => {
                    return {
                        ...object,
                        open: true,
                        right: true,
                        text: "¡Recibimos tus picks!"
                    }
                });
            } else {
                setModalObject(object => {
                    return {
                        ...object,
                        open: true,
                        right: false,
                        text: "Algo salió mal, intenta de nuevo."
                    }
                });
            }
        }
    };

    const handleClose = () => {
        setModalObject(object => {
            return {
                ...object,
                open: false
            }
        });
    };
    
    return (
        <>
            <HeaderPool data={dataSeason} sponsor={sponsor} />
            <MatchdayForm onSubmit={handleSubmit(onSubmit)}>
                { (!loadingSeason && !loadingMatch && !loadingDay && !loadingUser && matches.length > 0) ? 
                    ( matches.map( (match, index) => {
                            const userPick = _.find(matchesUser, ['matchUuid', match.uuid]);

                            return <Match 
                                key={match.uuid}
                                match={match}
                                control={control}
                                unregister={unregister}
                                userPick={userPick}
                            />
                        })
                    )
                :
                    ( [0,1,2,3,4,5,6,7].map( index => {
                            return <Match key={index} />
                        })
                    )
                }
                <SendButtonDesk type="submit"></SendButtonDesk>
                <SendButtonMobile type="submit"></SendButtonMobile>
            </MatchdayForm>
            { (!loadingSeason && !loadingMatch && !loadingDay && !loadingUser && !loadingPool && matches.length > 0) &&
                <Modal sponsor={sponsor} open={modalObject.open} right={modalObject.right} text={modalObject.text} handleClose={handleClose} />
            }        
        </>
    );
}

 
export default Matchdays;