import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    PoolsIcon,
    GroupsIcon,
    ResultsIcon,
    TopIcon,
    AwardsIcon,
    CloseSessionIcon,
    UserIcon,
    ChangeIcon
} from 'theme/SVGIcons';

export const Container = styled.div`
    max-width:304px;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const LogoContainer = styled(Link)`
    margin: 50px 0px;
`;

export const ListContainer = styled.ul`
    width: 100%;
    margin: 0px;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 32px;
    padding: 0px;
`;

export const LinkToSection = styled(Link).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          (!['active'].includes(prop) && defaultValidatorFn(prop)) ||
          (!['isMobileOnly'].includes(prop) && defaultValidatorFn(prop)) ,
    })`
    text-decoration: none !important;
    color: ${props => props.active ? '#3F9E34':'#000000'};

    &:hover {
        color: #3F9E34;
    }

    @media (min-width: 769px)  {
        display: ${props => props.isMobileOnly ? 'none' : 'block' };
    }
`;

export const LinkToSectionFloat = styled(LinkToSection)`
    position: absolute;
    bottom: 20px;

    @media (max-height: 450px) and (min-width: 768px) {
        position: static;
    }

    @media (max-height: 550px) and (max-width: 769px) {
        position: static;
    }
`;



export const SectionList = styled('li').withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 100%;
    display: grid;
    border-left: 3px solid ${props => props.active ? '#3F9E34':'transparent'};
    justify-content: flex-start;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 12px;
    padding-left: 20px;
    cursor: pointer;
`;

export const PoolsLogo = styled(PoolsIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;

export const ResultsLogo = styled(ResultsIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;


export const TopLogo = styled(TopIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;


export const AwardsLogo = styled(AwardsIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;

export const GroupLogo = styled(GroupsIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;

export const CloseSessionLogo = styled(CloseSessionIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;

export const UserLogo = styled(UserIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;

export const ChangeLogo = styled(ChangeIcon).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          !['active'].includes(prop) && defaultValidatorFn(prop),
    })`
    width: 24px;
    height: 24px;
    fill: ${props => props.active ? '#3F9E34':'#000000'};

    ${LinkToSection}:hover & {
        fill: #3F9E34;
    }
`;


