import {useState} from 'react';
import { Card , ButtonJF, Input } from 'components';

import { useResetPassword } from 'services/Public/usePublicService';

import { 
    Title, 
    FormContainer,
    InputContainer,
    ErrorParagraph,
} from './styles';
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as Eye } from 'assets/eye.svg';

const ResetCard = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();   
    const history = useHistory();
    const location = useLocation();

    const [ showSuccess, setShowSuccess ] = useState(false);
    const { loading, handleResetPassword } = useResetPassword();
    
    const onSubmit = async resetData => {
        const queryParams = new URLSearchParams(location.search);
        resetData.code = queryParams.get("code").replaceAll(' ', '+');

        await handleResetPassword({resetData}).then(response => {
            if(response?.data?.status === 200){
                setShowSuccess(true);
            }
        }).catch(err => console.log(err));
    };
    
    const redirect = () => {
        history.push({
            pathname: '/',
        });
    };

    return (
        <>
        { showSuccess ?
            <Card noArrow="true">
                <Title>Contraseña reseteada</Title>
                <ButtonJF type='button' onClick={redirect} text='¡Juega ya!'/>
            </Card>
        :
            <Card noArrow="true">
                <Title>Resetear contraseña</Title>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <InputContainer>
                        <Input 
                            where='reset'
                            name='password'
                            placeholder='Contraseña'
                            type='password'
                            control={control}   
                            required                  
                            Icon={Eye}
                        />
                        {(undefined !== errors.password && errors.password.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='reset'
                            name='confirmPassword'
                            placeholder='Repetir contraseña'
                            type='password'
                            control={control}   
                            required                  
                            Icon={Eye}   
                        />
                        {(undefined !== errors.confirmPassword && errors.confirmPassword.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    { !loading && <ButtonJF type='submit' text='Cambiar contraseña'/>}
                </FormContainer>
            </Card>
        }
        </>
    );
}

export default ResetCard;