import useAxiosAuthInstance from 'services/Auth/useAxiosAuthInstance';
import * as Cookie from 'services/CookieService'; 
import { CookieName } from 'constants/Constants';

export const useUserByCookie = () => {
    const [{data, loading, error}, getUser] = useAxiosAuthInstance({
        url: `/user/me`,
        method: 'GET',
        headers: {'Authorization': `Bearer ${Cookie.get(CookieName)}`}
    }, {manual: true});

    return {data, loading, error, getUser};
}

export const useLoginService = () => {    
    const [{data, error, loading}, login] = useAxiosAuthInstance({
        url: `/auth/login`,
        method: 'POST'
    }, {manual: true});

    const handleLogin = async ({email, password}) => {
        try{
            const response = await login({data: {email, password}});
            return response;
        } catch (e) {
            console.log(e);
        }
    } 

    return { data, error, loading, handleLogin };
}

export const useRegisterService = () => {
    const [{data, error, loading}, register] = useAxiosAuthInstance({
        url: `/auth/signup`,
        method: 'POST'
    }, {manual: true});

    const handleRegister = async (formData) => {
        try{
            const response = await register({data:formData.formData});
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    return {data, error, loading, handleRegister};
}

export const useRegisterExtraInfoService = () => {
    const [{data, error, loading}, registerExtraInfo] = useAxiosAuthInstance({
        url: `/user/extra-info`,
        method: 'POST'
    }, {manual: true});

    const handleRegisterExtraInfo = async (extraInfo) => {
        try{
            const response = await registerExtraInfo({data:extraInfo.extraInfo, headers:{'Authorization': `Bearer ${extraInfo.accessToken}`}});
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    return {data, error, loading, handleRegisterExtraInfo};
}

