import PropTypes from 'prop-types';


import { useLocation } from 'react-router-dom';
import { UserInfo } from 'components';
import {
    HeaderContainer,
    Title,
    GreenTitle,
    BackgroundTitle,
    LogoContainer,
    Rectangle
} from './styles';
import JFLogo from 'assets/logo-jf.svg';

const Locations = {
    '':'Root',
    'quinielas': 'Quinielas',
    'grupos': 'Grupos',
    'resultados': 'Mis Resultados',
    'top-ten': 'Top 10',
    'premios': 'Premios',
    'faq': 'Preguntas frecuentes',
    'legales': 'Legales',
    'perfil': 'Perfil',
    'password': 'Contraseña'
}

const LocationsInside = {
    'perfil':true
}

const PrivateHeader = ({toggleSideBar}) => {
    const locationObj = useLocation();
    const pathSplit = locationObj.pathname.split('/');
    let location = pathSplit[1];
    if(LocationsInside[location] && pathSplit.length > 2 && Locations[pathSplit[2]]){
        location = pathSplit[2];
    }

    const rectangleStyle = {position: 'absolute', left: 0, zIndex: -1}

    return (
        <HeaderContainer>
            <BackgroundTitle>
                <Rectangle style={rectangleStyle}/>
                <Title>{Locations[location]}</Title>
            </BackgroundTitle>
            <GreenTitle>{Locations[location]}</GreenTitle>
            <LogoContainer to="/">
                <img src={JFLogo} alt='juanfutbol' />
            </LogoContainer>
            <UserInfo toggleSideBar={toggleSideBar}/>
        </HeaderContainer>
    );
};

PrivateHeader.propTypes = {
  toggleSideBar: PropTypes.func,
};

export default PrivateHeader;