import React from 'react';
import { RegisterCard } from 'components';
import { RegisterContainer } from './styles';


const Register = () => {
    return (
        <RegisterContainer>
            <RegisterCard />
        </RegisterContainer>
    )
}

export default Register;
