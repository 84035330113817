import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card , ButtonJF, Input } from 'components';
import { 
    Title, 
    FormContainer,
    InputContainer,
    AgreementContainer,
    AgreementParagraph,
    TermsAndConditions,
    TryLoginContainer,
    TryLoginLink,
    ErrorParagraph,
    SuccessContainer,
    ThankyouParagraph,
    SendEmailParagraph
} from './styles';
import { Checkbox } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { useRegisterService, useRegisterExtraInfoService } from 'services/Auth/useAuthService';
import * as Selects from 'constants/SelectCatalogue';
import { ReactComponent as User } from 'assets/user.svg';
import { ReactComponent as Email } from 'assets/mail.svg';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { ReactComponent as Calendar } from 'assets/calendar.svg';
import { ReactComponent as Arrow } from 'assets/arrow-down.svg';
import { ReactComponent as SuccessIcon } from 'assets/success.svg';

const RegisterCard = ({ handleLogin }) => {
    const [ emailRegister, setEmailRegister ] = useState();
    const [ showSuccess, setShowSuccess ] = useState(false);
    const { control, handleSubmit, formState: { errors } } = useForm();        
    const { data, error: responseError, loading, handleRegister } = useRegisterService();
    const { handleRegisterExtraInfo } = useRegisterExtraInfoService();
    const [ needState, setNeedState ] = useState(false);
 
    const onSubmit = async formData => {
        
        let extraInfo = {
            "email": formData.email,
            "stateUuid": formData.stateUuid,
            "cellphoneNumber": formData.cellphoneNumber,
            "countryUuid": formData.countryUuid
        }
        delete formData.stateUuid;
        delete formData.cellphoneNumber;
        delete formData.countryUuid;
        
        await handleRegister({formData}).then(response => {
            handleRegisterExtraInfo({extraInfo, accessToken: response?.data?.accessToken}).then(response => {

            }).catch(err => console.log(err));
        }).catch(err => console.log(err));
        setEmailRegister(formData.email);        
    };

    useEffect(() => {
        if ( data && data.hasOwnProperty('accessToken')) {
            setShowSuccess(true);
        }
        if ( responseError ) {
            console.log(responseError.response.data.message);
            setEmailRegister(undefined);
        } 
    }, [data, responseError])

    const handleCountryChange = (event: object) => {
        if(event.target.value === "96e08eb9-8ea2-4639-9301-2eae93fe5370"){
            setNeedState(true)
        }else{
            setNeedState(false)
        }
    }

    return(
        <>
        { showSuccess ?
            <Card width={396}>
                <SuccessContainer>
                    <SuccessIcon />
                    <ThankyouParagraph>
                        ¡Gracias por registrarte!
                    </ThankyouParagraph>
                    <SendEmailParagraph>
                        Te hemos enviado un correo a<br/>
                        {emailRegister}<br/>
                        Ahora podrás inciar sesión
                    </SendEmailParagraph>
                    <ButtonJF type='button' text='Iniciar sesión' disabled={false}/>
                </SuccessContainer>
            </Card>
            :
            <Card width={396}>
                <Title>Regístrate</Title>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <InputContainer>
                        <Input
                            where='register'
                            name='firstName'
                            placeholder='Nombre'
                            type='text'
                            control={control}
                            required
                            Icon={User}
                        />
                        {(undefined !== errors.firstName && errors.firstName.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input
                            where='register'
                            name='lastName'
                            placeholder='Apellido'
                            type='text'
                            control={control}
                            required
                            Icon={User}
                        />
                        {(undefined !== errors.lastName && errors.lastName.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='register'
                            name='email'
                            placeholder='Correo electrónico'
                            type='email'
                            control={control} 
                            required
                            Icon={Email}
                        />
                        {(undefined !== errors.email && errors.email.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='register'
                            name='password'
                            placeholder='Contraseña'
                            type='password'
                            control={control}   
                            required                  
                            Icon={Eye}
                        />
                        {(undefined !== errors.password && errors.password.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='register'
                            name='confirmPassword'
                            placeholder='Repetir contraseña'
                            type='password'
                            control={control}   
                            required                  
                            Icon={Eye}   
                        />
                        {(undefined !== errors.confirmPassword && errors.confirmPassword.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='register'
                            name='dateBirth'
                            placeholder='Fecha de nacimiento'
                            type='date'
                            control={control}   
                            required                  
                            Icon={Calendar}
                        />
                        {(undefined !== errors.dateBirth && errors.dateBirth.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='register'
                            name='gender'
                            placeholder='Sexo'
                            type='select'
                            control={control}             
                            options={Selects.Sex}                   
                        />
                    </InputContainer>
                    <InputContainer>
                        <Input 
                            where='register'
                            name='countryUuid'
                            placeholder='País'
                            type='select'
                            control={control}             
                            options={Selects.Countries}
                            onChange={handleCountryChange}
                            required                 
                        />
                        {(undefined !== errors.countryUuid && errors.countryUuid.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    { (needState) && 
                        <InputContainer>
                            <Input 
                                where='register'
                                name='stateUuid'
                                placeholder='Estado'
                                type='select'
                                control={control}             
                                options={Selects.States}
                                required                 
                            />
                            {(undefined !== errors.stateUuid && errors.stateUuid.type === 'required') && 
                                <ErrorParagraph>Campo requerido</ErrorParagraph>
                            }
                        </InputContainer>
                    }
                    <InputContainer>
                        <Input 
                            where='register'
                            name='cellphoneNumber'
                            placeholder='Teléfono'
                            type='phone'
                            control={control}   
                            required                  
                            Icon={Arrow}
                        />
                        {(undefined !== errors.cellphoneNumber && errors.cellphoneNumber.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <AgreementContainer>
                            <Checkbox
                                name='agreement'
                                color="primary"
                                required
                            />
                            <AgreementParagraph>
                                Estoy de acuerdo con los {' '}
                                <TermsAndConditions to='/'>Términos y Condiciones</TermsAndConditions> de
                                Uso de juanfutbol y deseo recibir promociones
                                relacionadas.
                            </AgreementParagraph>
                        </AgreementContainer>
                    </InputContainer>
                    { !loading && <ButtonJF type='submit' text='¡Sé parte del juego!'/>}
                </FormContainer>
                <TryLoginContainer>
                    ¿Ya tienes una cuenta?{' '}
                    <TryLoginLink onClick={ handleLogin } >Inicia sesión</TryLoginLink>
                </TryLoginContainer>
            </Card>
        }
        </>
    )
}

RegisterCard.propTypes = {
  handleLogin: PropTypes.func,
}

export default RegisterCard;