import React from 'react';
import { ResetCard } from 'components';
import { ResetContainer } from './styles';

const Reset = () => {
    return (
    	<ResetContainer>
    		<ResetCard/>
    	</ResetContainer>
    )
}

export default Reset;
