import React from 'react';
import { Modal as ModalUI } from '@material-ui/core';
import { ButtonJF } from 'components';

import {
    ContentModal,
    Text,
    Icon,
    Sponsor
} from './styles';

const Modal = ({text, open, right, sponsor, handleClose }) => {
    return (
        <>
            { open && 
                <ModalUI open={open} onClose={handleClose}>
                    <ContentModal>
                        {(sponsor) &&
                            <Sponsor>
                                <img src={require(`assets/${sponsor}-dark.png`).default} alt={sponsor}/>
                            </Sponsor>
                        }
                        <Text>{text}</Text>
                        <Icon isRight={right}/>
                        <ButtonJF onClick={handleClose} text="Aceptar" />
                    </ContentModal>
                </ModalUI>
            }
        </>
    );
 }

 export default Modal;