import styled from 'styled-components';

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.26px;
    text-align: center;
    color: #646761;
    margin-top: 0px;
`;

export const UpperContainer = styled.p`
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
`;

export const FormContainer = styled.form`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 16px;
`;

export const BottomContainer = styled.p`
    text-align: center;
    margin-top: 25px;
`;

export const CountParagraph = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #646761;
`;

export const LoginLink = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #3F9E34;
    text-decoration: underline;
    cursor:pointer;
`;

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
`;

export const SentEmailParagraph = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
`
export const InfoParagraph = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
`;