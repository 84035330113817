import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/logo-jf.svg';
import { ReactComponent as ArrowUp } from 'assets/arrow-up.svg'

export const CardContainer = styled.div`
    width: 100%;
    max-width: 500px;
    position: relative;
    height: auto;
    max-height: 90vh;

    @media (min-width: 769px)  {
        width: 324px;
    }
`;

export const ArrowMenu = styled(ArrowUp)`
    position: absolute;
    margin: auto;
    top: -12px;
    display: none;

    @media (min-width: 769px)  {
        display: block;
    }
`;

export const CardMain = styled.div`
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 11px rgba(100, 103, 97, 0.25);
    border-radius: 35px;
    padding: ${props => props.hasPadding ? '0px 25px 20px 25px':'0px'};

    @media (min-width: 769px)  {
        border-radius: 12px;
    }
`;

export const CardHeader = styled.div`
    width: 100%;
    height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardLogo = styled(Logo)`
    width: 164px;
`;

export const CardBody = styled.div`
    max-height: calc(90vh - 115px);
    overflow-y: auto;
`;