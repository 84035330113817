import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { PrivateLayout } from 'sections';
import * as ROUTES from 'constants/Routes';

const PrivateRoute = ({components: Component, ...rest}) => {
    const { cookie } = useContext(UserContext);
    return (
    <Route 
        {...rest} 
        
        render={ props => {
            if (cookie) {
                return (
                    <PrivateLayout>
                        <Component {...props} />
                    </PrivateLayout>
                )
            }            
            return (
                <Redirect
                    to={{
                        pathname: ROUTES.HOME,
                        state: { from: props.location }
                    }}
                />
            )
        }
    } />
)}



export default PrivateRoute;