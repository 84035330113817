import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { 
    useCompetitionByPermalink,
    useGetSeasonsByCompetition
} from 'services/Private/useGameService';

import { 
    useRankingBySeason
} from 'services/Private/useRankingService';

import { TopCard } from 'components';
import { TopRow } from 'components';

import {
    HorizontalListContainer,
    VerticalListContainer,
    Separator,
    TitleRanking,
    RankingContainer,
    WhenNoHeader
} from './styles';

const Ranking = () => {
    const { permalink } = useParams();
    const { loading, getCompetitionsByPermalink } = useCompetitionByPermalink(permalink);
    const { loading: loadingSeasons, getSeasonsByCompetition } = useGetSeasonsByCompetition();
    const { loading:loadingRanking, getRankingBySeason } = useRankingBySeason();
const [ ranking, setRanking ] = useState([]);

    const getRanking = React.useCallback(async () => {
        try {
            const competitionResponse = await getCompetitionsByPermalink();
            let seasonUuid;
            if(competitionResponse.data.data.seasonUuid){
                seasonUuid = competitionResponse.data.data.seasonUuid;
            }else{
                const seasonsResponse = await getSeasonsByCompetition(competitionResponse.data.data.uuid)
                seasonUuid = seasonsResponse.data.data[0].uuid;
            }
            const rankingResponse = await getRankingBySeason(seasonUuid);

            setRanking(rankingResponse.data.data);
        } catch (e) {
            console.log(e)
        }
    }, [getCompetitionsByPermalink, getSeasonsByCompetition, getRankingBySeason]);


    useEffect( () => {
        getRanking();
    }, [getRanking]);    

    return (
		<RankingContainer>
            <WhenNoHeader>
                <Separator height={10} />
                <TitleRanking>Top 10</TitleRanking>
                <Separator height={10} />
            </WhenNoHeader>
	        <HorizontalListContainer>
	        	{ (!loading && !loadingSeasons && !loadingRanking && ranking.length > 0) ? 
                        ranking.slice(0,3).map(rank => ( 
                            <div key={rank.position}>
    			            	<TopCard position={rank.position} points={rank.points} name={rank.userName} uuid={rank.userUuid}></TopCard>
    			            </div>
                        ))
                    :
                        [1,2,3].map(position => ( 
                            <div key={position}>
                                <TopCard />
                            </div>
                        ))
                }
	        </HorizontalListContainer>
	        <VerticalListContainer>
	        	{ (!loading && !loadingSeasons && !loadingRanking && ranking.length > 0) ?
                        ranking.slice(3,10).map(rank => ( 
                            <div key={rank.position}>
        		            	<TopRow position={rank.position} points={rank.points} name={rank.userName} uuid={rank.userUuid}></TopRow>
        		            </div>
                        ))
                    :
                        [4,5,6,7,8,9,10].map(position => ( 
                            <div key={position}>
                                <TopRow />
                            </div>
                        ))
                }
	        </VerticalListContainer>
        </RankingContainer>
    )
}

export default Ranking
