import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card , ButtonJF, Input, ReadInput } from 'components';
import { 
    Title, 
    FormContainer,
    InputContainer,
    ButtonContainer,
    TypeContainer,
    TypeParagraph,
    TitleTypeParagraph,
    AcceptanceContainer,
    ErrorParagraph,
    SuccessContainer,
    ThankyouParagraph,
    GroupNameParagraph,
    ShareParagraph,
    SocialContainer,
    ExternalLink,
    Separator,
    LinkContainer,
    Close,
    GroupLogo
} from './styles';

import { Checkbox } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useCreateGroup } from 'services/Private/useGroupService';
import Facebook from 'assets/facebook.png';
import Twitter from 'assets/twitter.png';
import Whatsapp from 'assets/whatsapp.png';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';

const CreateGroupCard = ({ seasonUuid, closeDrawers }) => {
    const [ groupData, setGroupData ] = useState();
    const [ showSuccess, setShowSuccess ] = useState(false);
    const { control, handleSubmit, formState: { errors } } = useForm();        
    const { data, error: responseError, loading, handleCreateGroup } = useCreateGroup();

    const onSubmit = async formData => {
        formData.type = (formData.private)?1:2;
        formData.seasonUuid = seasonUuid;
        formData.status = 1;
        delete formData.private;

        await handleCreateGroup({formData}).then(response => {
        }).catch(err => console.log(err));      
    };

    useEffect(() => {
        if ( responseError ) {
            setGroupData(undefined);
            setShowSuccess(false);
        } else if(data && data?.data?.hasOwnProperty("name")){
            setGroupData(data?.data);
            setShowSuccess(true);
        }
    }, [data, responseError])

    return(
        <>
        { showSuccess ?
            <Card width={396} hasLogo={false} hasPadding={false}>
                <Close onClick={closeDrawers} />
                <SuccessContainer>
                    <GroupLogo />
                    <ThankyouParagraph>
                        ¡Gracias por crear tu grupo!
                    </ThankyouParagraph>
                    <GroupNameParagraph>
                        "{groupData?.name}"
                    </GroupNameParagraph>
                    <Separator/>
                    <SocialContainer>
                        <ExternalLink>
                            <img src={Facebook} alt="facebook" />
                        </ExternalLink>
                        <ExternalLink>
                            <img src={Twitter} alt="twitter" />
                        </ExternalLink>
                        <ExternalLink>
                            <img src={Whatsapp} alt="whatsapp" />
                        </ExternalLink>
                    </SocialContainer>
                    <ShareParagraph>
                        Comparte el link con tus amigos
                    </ShareParagraph>
                    <LinkContainer>
                        <ReadInput
                            where='create'
                            name='link'
                            value={`http://quiniela.stadium-azteca.com/grupos/euro-copa/${groupData?.permalink}`}
                            Icon={CopyIcon}
                        />
                    </LinkContainer>
                </SuccessContainer>
            </Card>
            :
            <Card width={396} hasLogo={false}>
                <Title>Crea un nuevo grupo</Title>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <InputContainer>
                        <Input
                            where='create'
                            name='name'
                            placeholder='Nombre del grupo'
                            type='text'
                            control={control}
                            required
                        />
                        {(undefined !== errors.name && errors.name.type === 'required') && 
                            <ErrorParagraph>Campo requerido</ErrorParagraph>
                        }
                    </InputContainer>
                    <InputContainer>
                        <TypeContainer>
                            <Controller
                                name="private"
                                control={control}
                                render={({ field }) => <Checkbox
                                            {...field}
                                            name='private'
                                            color="primary"
                                            control={control}
                                        />} 
                            />
                            <TypeParagraph>
                                <TitleTypeParagraph>Grupo privado</TitleTypeParagraph>
                                (Deberás aceptar a los nuevos integrantes)
                            </TypeParagraph>
                        </TypeContainer>
                    </InputContainer>
                    <AcceptanceContainer>
                        Al crear un nuevo grupo aceptas los términos y condiciones de juanfutbol. Más información
                    </AcceptanceContainer>
                    { !loading &&
                        <ButtonContainer>
                            <ButtonJF type='button' onClick={closeDrawers} text='Cancelar' isGray/>
                            <ButtonJF type='submit' text='Crear grupo'/>
                        </ButtonContainer>
                    }
                </FormContainer>
                
            </Card>
        }
        </>
    )
}

CreateGroupCard.propTypes = {
  seasonUuid: PropTypes.string,
  closeDrawers: PropTypes.func,
}

export default CreateGroupCard;