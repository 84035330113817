import React from 'react';
import { RecoverCard } from 'components';
import { RecoverContainer } from './styles';

const Recover = () => {
    return (
    	<RecoverContainer>
    		<RecoverCard/>
    	</RecoverContainer>
    )
}

export default Recover;
