import styled from 'styled-components';

import { ReactComponent as Gold } from 'assets/gold-band.svg';
import { ReactComponent as Silver } from 'assets/silver-band.svg';
import { ReactComponent as Bronce } from 'assets/bronce-band.svg';
import { ReactComponent as Green } from 'assets/green-band.svg';
import { ReactComponent as White } from 'assets/rectangle.svg';

export const TopContainer = styled.div`
    width: 100%;
    position: relative;

    &:after {
        content: " ";
        display: block;
        padding-bottom: 100%;
    }
`;

export const TopDataContainer = styled.div`
    position: absolute;
    width: 100%;
    height:100%;
    font-family: 'Montserrat';
    font-style: normal;
    letter-spacing: 0.175714px;
    color: #000000;
    overflow:hidden;

    display: grid;
    grid-template-columns: 5fr 22fr 23fr;
    grid-template-rows: 1fr 8fr 2fr 3fr 6fr;
    grid-template-areas: '. . .''. badge .''. . .''. points points''. name name';
    gap: 0px;    
`;

export const TopPoints = styled.div`
    grid-area: points;
    font-size: 3vw;
    line-height: 3.1vw;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: normal;
    margin-left: 6%;

    > .MuiSkeleton-root {
        width:40%;
        line-height: 5vw;
    }

    @media (min-width: 769px)  {
        font-size: 2vw;
        line-height: 2.1vw;

        > .MuiSkeleton-root {
            line-height: 4vw;
        }
    }
`;

export const TopPointsText = styled.span`
    font-size: 2.5vw;

    @media (min-width: 769px)  {
        font-size: 1.5vw;
    }
`;

export const TopName = styled.div`
    grid-area: name;
    font-size: 2.5vw;
    line-height: 2.6vw;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: normal;

    > .MuiSkeleton-root {
        width:40%;
        line-height: 5vw;
    }

    @media (min-width: 769px)  {
        font-size: 2vw;
        line-height: 2.1vw;

        > .MuiSkeleton-root {
            line-height: 4vw;
        }
    }
`;

export const TopIconGrid = styled.div`
    grid-area: badge;

    > .MuiSkeleton-root {
        margin:10%;
        width:80%;
        padding-bottom: 80%;
    }
`;

export const GoldBand = styled(Gold)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-right: 20%;
`;

export const SilverBand = styled(Silver)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-right: 20%;
`;

export const BronceBand = styled(Bronce)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-right: 20%;
`;

export const GreenBand = styled(Green)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-right: 20%;
`;

export const WhiteBand = styled(White)`
    position: absolute;
    top: 0px;
    bottom:0px;
    left:0px;
    right: 0px;
    margin-right: 20%;
`;