import React from 'react'
import useAxiosServingInstance from './useAxiosServingInstance';
import useAxiosAdminInstance from './useAxiosAdminInstance';

export const usePublicGroupsBySeason = () => {
    const [{data, error, loading}, handlePublicGroupList] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getPublicGroupsBySeason = React.useCallback(async (seasonUuid) => {
        try{
            const response = await handlePublicGroupList({
                url: `/v1/group/list/user/season/${seasonUuid}` //?fields=user,countParticipants
            });
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [handlePublicGroupList]);

    return {data, error, loading, getPublicGroupsBySeason};
}

export const useUserGroupsBySeason = () => {
    const [{data, error, loading}, handleUserGroupList] = useAxiosServingInstance({
        method: 'GET'
    }, {manual: true});

    const getUserGroupsBySeason = React.useCallback(async (seasonUuid) => {
        try{
            const response = await handleUserGroupList({
                url: `/v1/group/public/list/season/${seasonUuid}` //?fields=user,countParticipants
            });
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [handleUserGroupList]);

    return {data, error, loading, getUserGroupsBySeason};
}

export const useCreateGroup = () => {
    const [{data, error, loading}, createGroup] = useAxiosAdminInstance({
        url: `/v1/group`,
        method: 'POST'
    }, {manual: true});

    const handleCreateGroup = React.useCallback(async (formData) => {
        try{
            const response = await createGroup({data:formData.formData});
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [createGroup]);

    return {data, error, loading, handleCreateGroup};
}