import styled from 'styled-components';

export const PublicContainer = styled.div`
    display: grid;
    position:relative;
    grid-template-columns: 100%;
    grid-template-rows: 76px calc(100vh - 76px);
    grid-template-areas: 'header''main';

    @media (min-width: 769px)  {
        grid-template-rows: 98px calc(100vh - 98px);
    }
`;

export const HeaderContainer = styled.header`
    grid-area: header;
`;

export const MainContainer = styled.article`
    grid-area: main;
    overflow: auto;
`;

export const BlurContent = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: 0.3;
    top: 0px;
    left: 0px;
    z-index: 1050;
`;

export const DrawerContainer = styled.div`
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 1051;
    bottom: -10px;
    display: flex;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    transform: translateY(${props => props.showDrawer ? '0vh':'100vh'});
    right:0px;

    @media (min-width: 769px)  {
        position: absolute;
        width: auto;
        bottom: auto;
        top: 75px;        
        right: ${props => (props.login || props.recover) ? '20px':'215px'};
        display: ${props => props.showDrawer ? 'block':'none'};
    }

    @media (max-width: 768px)  {
        > div > div{
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
`;