import React, { useState } from 'react';
import { 
    QuestionsContainer,
    Question,
    QuestionsMain,
    Answer,
    QuestionGroup,
    MinusIcon,
    PlusIcon
} from './styles';


const QuestionsPanel = ({questions}) => {
    const [ items, setItems ] = useState(questions);

    const openPanel = (index) => {
        setItems(items => {
            let newArray = [...items];
            newArray[index].active = true;
            return newArray;
        });
    }

    const closePanel = (index) => {
        setItems(items => {
            let newArray = [...items];
            newArray[index].active = false;
            return newArray;
        });
    }

    return(

        <>
            {(items) &&
                <QuestionsContainer>
                    <QuestionsMain >
                    {items.map((question, index) => (
                        <QuestionGroup key={index}>
                            <Question>
                                {question.question}
                            </Question>
                            {(question?.active) ?
                                <MinusIcon onClick={() => { closePanel(index)}} />
                            :
                                <PlusIcon onClick={() => { openPanel(index)}} />
                            }
                            {(question?.active) &&
                                <Answer>
                                    {question.answer.split('\n').map((line, i) => (
                                    <span key={i}>
                                        {line}
                                        <br/>
                                    </span>
                                ))}
                                </Answer>
                            }
                        </QuestionGroup>
                    ))}
                    </QuestionsMain>
                    
                </QuestionsContainer>
            }
        </>
    )
};

export default QuestionsPanel;