import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { adminApi } from 'constants/ApiUrls';

const useAxiosPublicAdminInstance = makeUseAxios({
    axios: axios.create({
        baseURL: adminApi,
        headers: {
            'Content-Type':'application/json'
        }
    })
})

export default useAxiosPublicAdminInstance;