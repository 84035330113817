import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { authApi } from 'constants/ApiUrls';

const useAxiosAuthInstance = makeUseAxios({
    axios: axios.create({
        baseURL: authApi
    })
})

export default useAxiosAuthInstance;