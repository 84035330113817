import React, {useEffect, useState} from 'react';
import { Card , ButtonJF } from 'components';

import { useConfirmEmail } from 'services/Public/usePublicService';

import { 
    Title,
    BottomContainer
} from './styles';
import { useHistory, useLocation } from "react-router-dom";

const ConfirmCard = () => {
    const history = useHistory();
    const location = useLocation();

    const [verified, setVerified] = useState(false);
    const { handleConfirmEmail } = useConfirmEmail();
    
    const redirect = () => {
        history.push({
            pathname: '/',
        });
    };

    useEffect(() => {

        const queryParams = new URLSearchParams(location.search)

        const confirm = {
            "confirmToken": queryParams.get("confirmToken"),
            "uuid": queryParams.get("uuid")
        }
        
        handleConfirmEmail({confirm}).then(response => {
            if (response?.data)
                setVerified(true);
        }).catch(err => console.log(err));
    }, [handleConfirmEmail, location]);
    

    return (
        <Card hasArrow={false}>
            <Title>{verified ? "Correo Confirmado" : "Verificando Correo"}</Title>
            {(verified) &&
            <BottomContainer>
                <ButtonJF type='button' onClick={redirect} text='¡Juega ya!'/>
            </BottomContainer>
            }
        </Card>
    );
}

export default ConfirmCard;