import styled from 'styled-components';

export const PrivateContainer = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 76px calc(100vh - 76px);
    grid-template-areas: 'header'
                        'main';

    @media (min-width: 769px)  {
        grid-template-columns: 255px calc(100% - 255px);
        grid-template-rows: 120px calc(100vh - 120px);
        grid-template-areas: 'sidebar header'
                            'sidebar main';
    }
`;

export const SidebarContainer = styled.aside`
    position: fixed;
    width: 304px;
    height: 100vh;
    transition: all 0.5s ease-in-out;
    transform: translateX(${props => props.active ? '0px': '-304px'});
    z-index: 2;

    @media (min-width: 769px)  {
        position: relative;
        width: 255px;
        position: relative;
        grid-area: sidebar;
        transform: none;
    }

`;

export const HeaderContainer = styled.header`
    grid-area: header;
`;

export const MainContainer = styled.article`
    grid-area: main;
    overflow: auto;
`;

export const BlurZone = styled.div`
    position: fixed;
    background-color: #000000;
    opacity: 0.6;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;

    @media (min-width: 769px)  {
        display: none;
    }
`;
