import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Routes from 'constants/Routes';
import _ from "lodash";
import left from '../../assets/arrow-left.png';
import right from '../../assets/arrow-right.png';
import {
    CompetitionTopContainer,
    CompetitionBackSkeleton,
    CompetitionBack,
    Sponsor,
    BottomBarsSkeleton,
    CompetitionContainer,
    CompetitionName,
    ArrowBefore,
    ArrowAfter,
    InnerContainer,
    PointsContainer
} from './styles';
import { useGetSeasonPlaying } from 'services/Private/useGameService';
import { PickerBar } from 'components';

const HeaderPool = ({ data, sponsor, matchdays, pointsUser, hasBottomBars }) => {
    const { data: dataList, getSeasonPlaying } = useGetSeasonPlaying();
    const history = useHistory();
    const [ permalinks, setPermalinks ] = useState({"prev": null,"next": null});

    const competitionLink = React.useCallback((seasons) => { 
        const competitionList = seasons.map((competition, key) => (
            {
                key: key,
                permalink: competition.competition.permalinkEs
            }

        )); 

        const competitionPick = _.find(competitionList, ['permalink', data?.data.permalinkEs]);

        const getNextKey = competitionPick?.key === competitionList?.length - 1 ? 0 : competitionPick?.key + 1;
        const getPrevKey = competitionPick?.key > 0 ? competitionPick?.key - 1 : competitionList?.length - 1;

        const findNextPermalink = _.find(competitionList, ['key', getNextKey]);
        const findPrevPermalink = _.find(competitionList, ['key', getPrevKey]);

        setPermalinks({"prev": findPrevPermalink?.permalink,"next": findNextPermalink?.permalink})
    }, [data]);

    const callAPI = React.useCallback(async() => {
        if (dataList) {
            competitionLink(dataList?.data)
        }else {
            await getSeasonPlaying().then(
                response => competitionLink(response?.data.data)
            );
        }
    }, [getSeasonPlaying, competitionLink, dataList]);

    useEffect( () => {
        callAPI();
    }, [callAPI]);

    const handleNextCompetition = () => {
        if(permalinks.next !== null){
            if(matchdays){
                history.push(Routes.RESULTS_ID+'/'+permalinks.next);
            }else{
                history.push(Routes.POOLS+'/'+permalinks.next);
            }
        }
    }

    const handlePrevCompetition = () => {
        if(permalinks.prev !== null){
            if(matchdays){
                history.push(Routes.RESULTS_ID+'/'+permalinks.prev);
            }else{
                history.push(Routes.POOLS+'/'+permalinks.prev);
            }
        }
    }

    const handleChangeMatchday = (matchday) => {
        if(matchday !== null){
            history.push(Routes.RESULTS_ID+'/'+data?.data.permalinkEs+'/'+matchday);
        }
    }

    return (
        <CompetitionTopContainer>
            {(data) ?
                <CompetitionBack>
                    <InnerContainer>
                        <Sponsor>
                            <img src={require(`assets/${sponsor}.png`).default} alt={sponsor}/>
                        </Sponsor>
                        <CompetitionContainer>
                            <ArrowBefore onClick={handlePrevCompetition}>
                                <img alt='left' src={left} />
                            </ArrowBefore>
                            <CompetitionName>
                                {data?.data.nameEs}
                            </CompetitionName>
                            <ArrowAfter onClick={handleNextCompetition}>
                                <img alt='right' src={right} />
                            </ArrowAfter>
                        </CompetitionContainer>
                    </InnerContainer>
                </CompetitionBack>
            :
                <CompetitionBackSkeleton variant="rect" />
            }
            {(hasBottomBars || matchdays) &&
                ((matchdays) ?
                    <> 
                        <PickerBar 
                            matchdays={matchdays}
                            handleChangeMatchday={handleChangeMatchday}
                        />
                        <PointsContainer>
                            <span>{(pointsUser === 1) ? pointsUser + ' Pt' : pointsUser + ' Pts'}</span> esta jornada - <span>100 Pts</span> general
                        </PointsContainer>
                    </>
                :
                    <BottomBarsSkeleton variant="rect" />
                )
            }
        </CompetitionTopContainer>
    );
}
 
export default HeaderPool;