import styled from 'styled-components';

export const AvatarContainer = styled.div`
    margin:10%;
    width: 80%;
    border-radius: 50%;
    background: radial-gradient(60% 60% at 50% 50%, rgba(106, 236, 58, 0.72) 0%, rgba(106, 236, 58, 0.1872) 100%), linear-gradient(359.53deg, rgba(106, 236, 58, 0.4176) -21.17%, rgba(106, 236, 58, 0) 111.46%);
    filter: drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.24));
    position:relative;

    &:after {
        content: " ";
        display: block;
        padding-bottom: 100%;
    }
`;

export const Picture = styled.img`
    
`;

export const InitialsContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: ${props => props.isRow ? '2vw' : '3vw' };
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 25px;
`;

export const BadgeContainer = styled.div`
    position: absolute;
    bottom: -8%;
    right: -8%;
    width: 40%;
    height: 40%;

    border: 2px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;

    font-family: 'MuseoModerno';
    font-size: ${props => props.isRow ? '1.5vw' : '2.5vw' };
    display: flex;
    justify-content: center;
    align-items: center;

    margin: auto;

    color: rgba(0, 0, 0, 0.38);
`;
export const GoldBadgeContainer = styled(BadgeContainer)`
    background: linear-gradient(155.74deg, #AC9046 -4.76%, #E9D7AA 21.33%, #E0CC9B 46.89%, #AC9046 105.41%);
`;

export const SilverBadgeContainer = styled(BadgeContainer)`
    background: linear-gradient(155.74deg, #A5A5A5 -4.76%, #ECECEC 21.33%, #D1D1D1 46.89%, #616161 105.41%);
`;

export const BronceBadgeContainer = styled(BadgeContainer)`
    background: linear-gradient(155.74deg, #D19843 -4.76%, #F8DAAE 21.33%, #EEC588 46.89%, #D19843 105.41%);
`;

export const WhiteBadgeContainer = styled(BadgeContainer)`
    background: #FFF;
`;