import styled from 'styled-components';
import Live from '../../assets/live.png';

export const ContainerMatch = styled.div `
    padding: 10px;
    background-color: #fff;
    width:95%;
    margin: 0px auto 8px;
    height: auto;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 70px 1fr 160px 1fr 100px;
    grid-template-rows: 100%;
    grid-template-areas: 'date home radio away star';
    grid-gap: 0px;

    @media (max-width: 889px) {
        grid-template-columns: 70px 1fr 125px 1fr 20px;
    }

    @media (max-width: 768px) {
        grid-template-columns: 33% 34% 33%;
        grid-template-rows: 3fr 3fr 1fr 2fr;
        grid-template-areas: 'home date away''home date away''home star away''radio radio radio';

        margin-bottom: 0px;
        box-shadow: rgb(224 225 223) 0px -1px 0px inset;
        border-radius: 0px;

        background-color: transparent;
        height: 238px;

        margin: 0px;
        padding: 30px 0px;
        width:100%;
    }
`;

export const DateInfoContainer = styled('div').withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          (!['disable'].includes(prop) && !['noOpacity'].includes(prop) && defaultValidatorFn(prop)),
    })`
    grid-area: date;
    width: 66px;
    height: 75px;
    flex: initial;
    opacity: ${props => (props.disabled && !props.noOpacity)? ".3" : "1"};

    > .MuiSkeleton-rect {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    @media (max-width: 889px) {
        margin: 0px auto;
    }
`;

export const HourInfo = styled.div `
    width: 100%;
    height: 30%;
    background-color: #3F9E34;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

export const HourFinal = styled(HourInfo) `
    background-color: #888B85;
`;

export const DayInfo = styled.div `
    width: 100%;
    height: 40%;
    background: linear-gradient(0deg, #F8F9FA, #F8F9FA), #ECEEEF;
    text-align: center;
    font-size: 24px;
`;

export const MonthInfo = styled.div `
    width: 100%;
    height: 30%;
    background: linear-gradient(0deg, #F8F9FA, #F8F9FA), #ECEEEF;
    text-align: center;
    font-size: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const HomeTeamContainer = styled.div`
    grid-area: home;
    display:flex;
    align-items:center;
    justify-content: flex-end;

    > .MuiSkeleton-circle {
        width:52px;
        height:52px;
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
`;

export const HomeName = styled.div `
    font-size: 16px;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;

    > .MuiSkeleton-text {
        line-height: 4vw;
        width:70px;
    }

    @media (max-width: 991px) and (min-width: 769px)  {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        text-align:center;
        margin-right: 0px;
        margin-top: 30px;

        > .MuiSkeleton-text {
            line-height: 7vw;
        }
    }
`;

export const MatchRadioContainer = styled.div`
    grid-area: radio;
    display:flex;
    align-items:center;
    justify-content: center;
`;

export const AwayTeamContainer = styled.div`
    grid-area: away;
    display:flex;
    align-items:center;
    justify-content: flex-start;

    > .MuiSkeleton-circle {
        width:52px;
        height:52px;
    }

    @media (max-width: 768px) {
        justify-content: flex-start;
        flex-direction: column;
        text-align:center;
    }
`;

export const AwayName = styled(HomeName) `
    font-size: 16px;
    text-align: left;
    margin-left: 10px;
    font-weight: 600;

    > .MuiSkeleton-text {
        line-height: 4vw;
        width:70px;
    }

    @media (max-width: 991px) and (min-width: 769px)  {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        text-align:center;
        margin-left: 0px;
        margin-top: 30px;

        > .MuiSkeleton-text {
            line-height: 7vw;
        }
    }
`;

export const TeamAsset = styled.img `
    width: 45px;
    height: 45px;
`;

export const Outstanding = styled.div.withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
          (!['disable'].includes(prop) && !['noOpacity'].includes(prop) && defaultValidatorFn(prop)),
    })`
    grid-area: star;
    color: #CDB884;
    font-size: 15px;
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    opacity: ${props => (props.disabled && !props.noOpacity)? ".3" : "1"};

    img {
        margin-right: 5px;
        width:21px;
    }

    @media (max-width: 889px) and (min-width: 769px) {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        margin-left: -10px;
        font-size: 12px;

        img {
            margin-right: 2px;
            width:15px;
        }
    }

    @media (max-width: 768px) {
        text-align:center;
        justify-content:center;
        margin-top: -13px;
    }
`;

export const BackLive = styled.div `
    width: 100%;
    height: 100%;
    background: #000;
    background-image: url(${Live});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 8px;
`;