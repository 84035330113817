import styled from 'styled-components';

export const UserDropdownContainer = styled.div`
    width: 162px;
    height: 28px;
    position: relative;
    display: none;

    @media (min-width: 769px)  {
        display: block;
    }
`;

export const UserNameContainer = styled.div`
    height: 100%;
    position: relative;
    background: #FFFFFF;
    border-radius: 100px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    z-index: 3;
`;

export const UserName = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    display: inline-block;
`;

export const MenuDownContainer = styled.div`
    display: ${props => props.isOpen ? 'flex': 'none'};
    height: ${props => props.isOpen ? '109px': '0px'};
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: 2;
    justify-content: flex-end;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 12px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #E6E7E6;
`;

export const MenuButton = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    text-align: center;
    cursor: pointer;    
    justify-content: center;
    align-items: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    
    &:hover {
        background-color: #E6E7E6;
    }
`;

export const MenuButtonBottom = styled(MenuButton)`
    color: #DA2222;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;

