import React from 'react';
import { useParams } from 'react-router-dom';

import {
    VerticalListContainer,
    Separator,
    TitleRanking,
    PricesContainer,
    WhenNoHeader,
    SponsorLogo,
    TitlePrices,
    SubtitlePrices
} from './styles';

import {
    PriceCard
} from 'components';

const Prices = () => {
    const { permalink } = useParams();
    if(permalink){
        //dosomething
    }


    const sponsor = "samsung"
    const price = {
        type: "Premio Final",
        description: "Televisor Samsung 43” UHD 4k Smart TV",
        image: "https://s3-ap-southeast-1.amazonaws.com/s3.loopme.ph/img/newos/posts/2x/24018_nwnHModmp6LXeunw_.jpg"
    }
    
    return (
    	<>
    		{ (price) && 
    			<PricesContainer>
                    <WhenNoHeader>
                        <Separator height={10} />
                        <TitleRanking>Premios</TitleRanking>
                        <Separator height={10} />
                    </WhenNoHeader>
			        <VerticalListContainer>
                        <SponsorLogo>
                            <img src={require(`assets/${sponsor}-dark.png`).default} alt={sponsor}/>
                        </SponsorLogo>
                        <TitlePrices>Participa en la quiniela juanfutbol</TitlePrices>
                        <SubtitlePrices>¡Y gana increíbles premios!</SubtitlePrices>
                        <PriceCard price={price}></PriceCard>
			        </VerticalListContainer>
		        </PricesContainer>
		    }  
	    </>
    )
}

export default Prices
