import {
    useState,
    useContext,
    useCallback
} from 'react';
import { useLoginService } from 'services/Auth/useAuthService';
import * as Cookie from 'services/CookieService'; 
import { CookieName } from 'constants/Constants';
import { UserContext } from 'context/UserContext';

const useUser = () => {
    const { setUser, setCookie } = useContext(UserContext);
    const [ state, setState ] = useState({ loading: false, error:false });
    const { handleLogin } = useLoginService();

    const login = useCallback(({email, password}) => {
        setState({loading:true, error: false})
        handleLogin({email, password})
            .then(response => {
                saveCookie(response.data.accessToken);
                setCookie(response.data.accessToken);
                setState({loading: false, error: false });
            })
            .catch(err => console.log(err));
    }, [setCookie, handleLogin]);

    const logout = useCallback(() => {
        Cookie.remove(CookieName);
        setCookie(null);
        setUser(null);
    }, [setCookie, setUser])

    const saveCookie = token => {
        let date = new Date();
        date.setTime(date.getTime() + (60 * 24 * 60 * 1000));
        const options = { path: '/', expires: date };
        Cookie.set(CookieName, token, options);
    }

    return {
        login,
        logout,
        isLoading: state.loading,
        hasError: state.error,
    }
}

export default useUser;