import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { PrivateHeader, PrivateSidebar } from 'components';
import { 
    PrivateContainer,
    SidebarContainer,
    HeaderContainer,
    MainContainer,
    BlurZone
} from './styles';

const PrivateLayout = ({ children }) => {
    const windowScroll = useRef(null);
    const history = useHistory();
    const [showSideBar, setShowSideBar] = useState(false);
    const toggleSideBar = () => setShowSideBar(!showSideBar)
    const hideSideBar = () => setShowSideBar(false)

    useEffect(() => {
        let prevPathname = history.location.pathname;

        const unlisten = history.listen((location) => {
            if(windowScroll != null && windowScroll.current){

                const newBreak = location.pathname.split("/");
                const oldBreak = prevPathname.split("/");

                if(newBreak[1] !== oldBreak[1] || !newBreak[2] || !oldBreak[2]){
                    windowScroll.current.scrollTo(0, 0);
                }
                
                prevPathname = location.pathname;
            }
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return(
        <PrivateContainer>
            {showSideBar && <BlurZone onClick={hideSideBar}/>}
            <HeaderContainer>
                <PrivateHeader toggleSideBar={toggleSideBar}/>
            </HeaderContainer>
            <SidebarContainer active={showSideBar}>
                <PrivateSidebar hideSideBar={hideSideBar} />
            </SidebarContainer>
            <MainContainer ref={windowScroll}>{children}</MainContainer>
        </PrivateContainer>
    )
}

export default PrivateLayout;