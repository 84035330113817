import React from 'react'
import useAxiosServingInstance from './useAxiosServingInstance';
import useAxiosAdminInstance from './useAxiosAdminInstance';

export const useChangePassword = () => {
    const [{data, error, loading}, changePassword] = useAxiosAdminInstance({
        url: `/v1/user/update/password`,
        method: 'PUT'
    }, {manual: true});

    const handleChangePassword = React.useCallback(async (formData) => {
        try {
            const response = await changePassword({data: formData.formData});
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [changePassword]);

    return { data, error, loading, handleChangePassword};
}

export const useUserByCookie = () => {
    const [{data, loading, error}, handleGetUser] = useAxiosServingInstance({
        url: `/v1/user/me`,
        method: 'GET'
    }, {manual: true});

    const getUser = React.useCallback(async () => {
        try {
            const response = await handleGetUser();
            return response;
        } catch(e) {
            console.log(e);
        }
    }, [handleGetUser]);

    return {data, loading, error, getUser};
}

export const useUpdateInfo = () => {
    const [{data, error, loading}, updateInfo] = useAxiosAdminInstance({
        url: `/v1/user/update/info`,
        method: 'PUT'
    }, {manual: true});

    const handleUpdateInfo = React.useCallback(async (formData) => {
        try {
            const response = await updateInfo({data: formData.formData});
            return response;
        } catch(e) {
            console.log(e);
        }
     }, [updateInfo]);

    return { data, error, loading, handleUpdateInfo};
}
