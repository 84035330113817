import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useUser from 'hooks/useUser';
import {
    UserDropdownContainer,
    UserNameContainer,
    UserName,
    MenuDownContainer,
    MenuButton,
    MenuButtonBottom,
    Divider
} from './styles';
import * as Routes from 'constants/Routes';


import { ReactComponent as ArrowDown } from 'assets/arrow-down-name.svg';

const UserDropdown = ({completeName}) => {
    const history = useHistory();
    const [ isOpen, setIsOpen ] = useState(false)
    const handleOpenMenu = () => setIsOpen(!isOpen);
    const { logout } = useUser();
    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleCloseSession = () => {
        logout();
    }

    const handleClickProfile = () => {
        history.push(Routes.PROFILE);
        closeMenu();
    }
    
    return(
        <UserDropdownContainer>
            <UserNameContainer onClick={handleOpenMenu}>
                <UserName>{completeName}</UserName>
                <ArrowDown />
            </UserNameContainer>
            <MenuDownContainer isOpen={isOpen} onBlur={closeMenu}>
                <MenuButton onClick={handleClickProfile}>Mi perfil</MenuButton>
                <Divider />
                <MenuButtonBottom onClick={handleCloseSession}>
                    Cerrar sesión
                </MenuButtonBottom>
            </MenuDownContainer>
        </UserDropdownContainer>
    );
}

export default UserDropdown;