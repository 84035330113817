import PropTypes from 'prop-types';
import {
    TopContainer,
    TopDataContainer,
    TopName,
    TopPoints,
    TopPointsText,
    TopIconGrid,
    GoldBand,
    SilverBand,
    BronceBand,
    GreenBand
} from './styles';

import { TopIcon } from 'components';
import { 
    Skeleton
} from '@material-ui/lab';

const TopRow = ({name, uuid, points, position, media }) => {
    const isMe = false;
    const breakApartName = (name.indexOf(" ") > 0)?name.split(" "):[name, ""];

    return (
        <TopContainer isMe={isMe}>
            <TopDataContainer>
                <TopIconGrid>
                    { (name) &&
                        ( (isMe) ?
                            <GreenBand />
                        :
                            {
                                '1': <GoldBand />,
                                '2': <SilverBand />,
                                '3': <BronceBand />
                            }[position]
                        )
                    } 
                                
                    { (name) ?
                        <TopIcon isRowIcon firstName={breakApartName[0]} lastName={breakApartName[1]} position={position}/>
                    :
                        <Skeleton variant="circle"/>
                    }
                </TopIconGrid>
                <TopPoints>
                    { (name) ?
                        (
                            <>
                                {points}
                                <TopPointsText>{
                                    ` ${points > 1 ?
                                    'Pts' : 'Pt' }`
                                }</TopPointsText>
                            </>
                        )
                    :
                        <Skeleton />
                    }
                </TopPoints>
                <TopName>
                    { (name) ?
                        name
                    :
                        <Skeleton />
                    }
                </TopName>
            </TopDataContainer> 
        </TopContainer>
    )
}

TopRow.propTypes = {
    name: PropTypes.string,
    uuid: PropTypes.string,
    points: PropTypes.number,
    position: PropTypes.number,
};

TopRow.defaultProps = {
    name: "",
    uuid: "",
    points: 0,
    position: 4,
};

export default TopRow;