import React, { useState } from 'react';
import { 
    LegalsContainer,
    Legal,
    LegalsMain,
    Description,
    LegalGroup
} from './styles';


const LegalsPanel = ({legals}) => {
    const [ items ] = useState(legals);

    return(

        <>
            {(items) &&
                <LegalsContainer>
                    <LegalsMain >
                    {items.map((legal, index) => (
                        <LegalGroup key={index}>
                            <Legal>
                                {legal.title}
                            </Legal>
                            <Description>
                                {legal.description.split('\n').map((line, i) => (
                                    <span key={i}>
                                        {line}
                                        <br/>
                                    </span>
                                ))}
                            </Description>
                        </LegalGroup>
                    ))}
                    </LegalsMain>
                    
                </LegalsContainer>
            }
        </>
    )
};

export default LegalsPanel;