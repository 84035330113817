import React from 'react';

import {
    StyledFormControlLabel,
    StyledRadio,
    StyledRadioIcon,
    StyledRadioIconChecked
} from './styles';

const Radio = ({withLabel, noOpacity, label, value, disabled, className, ...props}) => {
    return (
        <>
            { (withLabel) ?
                <StyledFormControlLabel
                    value={value}
                    control={<Radio {...props}/>}
                    label={label}
                    labelPlacement="bottom"
                    className={className}
                    disabled={disabled}
                    noOpacity={noOpacity}
                  />
            :
                <StyledRadio
                    disableRipple
                    color="default"
                    checkedIcon={<StyledRadioIconChecked />}
                    icon={<StyledRadioIcon />}
                    {...(value && {value:value})}
                    {...(disabled && {disabled:disabled})}
                    {...props}
                />
            }
        </>
    );
 }

 export default Radio;