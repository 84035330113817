import styled from 'styled-components';
import { 
    FilledInput,
} from '@material-ui/core';

export const RoundedFilledInput = styled(FilledInput)`
	&.MuiFilledInput-root:hover{
    	background-color: #F6F6F6;	
	}
	&.MuiFilledInput-root {
    	border-radius:20px;
    	background-color: #F6F6F6;
    	color: #646761;
    }
`;