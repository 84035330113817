import React from 'react';
import { ConfirmCard } from 'components';
import { ConfirmContainer } from './styles';

const Confirm = () => {
    return (
    	<ConfirmContainer>
    		<ConfirmCard/>
    	</ConfirmContainer>
    )
}

export default Confirm;
