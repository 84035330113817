import styled from 'styled-components';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import { ReactComponent as Minus } from '../../assets/minus.svg';

export const QuestionsContainer = styled.div`
    width: 92%;
    position: relative;
    height: auto;
    margin: 4%;

    @media (max-width: 400px)  {
        width: 100%;
        margin: 0px;
    }
`;

export const QuestionGroup = styled.div`
    border-bottom: 1px solid #DDE0E3;
    position: relative;
`;

export const Question = styled.div`
    width: 100%;
    min-height:80px;
    padding:0 30px 0 3%;
    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #000000;
`;

export const Answer = styled.div`
    padding:0 3% 30px 3%;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    color: #000000;
`;  

export const QuestionsMain = styled.div`
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 11px rgba(100, 103, 97, 0.25);
    border-radius: 12px;
    text-align: left;

    ${QuestionGroup}:last-child { border-bottom: none; }

    @media (max-width: 400px)  {
        border-radius: 0px;
        box-shadow: none;
    }
`;

export const MinusIcon = styled(Minus)`
    position: absolute;
    min-height:80px;
    display: flex;
    align-items: center;
    right: 3%;
    top:0px;
    cursor:pointer;
`;
export const PlusIcon = styled(Plus)`
    position: absolute;
    min-height:80px;
    display: flex;
    align-items: center;
    right: 3%;
    top:0px;
    cursor:pointer;
`;

