export const HOME = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTER = '/register';
export const TOURNAMENTS = '/torneos';
export const TOURNAMENT_ID = '/torneo';
export const GROUPS_ID = '/grupos';
export const RESULTS_ID = '/resultados';
export const TOP_TEN_ID = '/top-ten';
export const AWARDS_ID = '/premios';
export const PROFILE = '/perfil';
export const PASSWORD = '/perfil/password';
export const CONFIRM = '/confirm/email';
export const REDIRECT = '/oauth2/redirect';
export const NOT_FOUND= '/not-found';
export const POOLS = '/quinielas';
export const RECOVER = '/recuperar';
export const RESET = '/reset/password';
export const FAQ = '/faq';
export const LEGALS = '/legales';