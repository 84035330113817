import styled from 'styled-components';

export const LoadingContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px;
    top: 0px;
    z-index: 10000;
`;

export const Loader = styled.div`
    width:300px;
    height:300px;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#3F9E34);
    mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;

    @keyframes s3 {to{transform: rotate(1turn)}}
`;

  