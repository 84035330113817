import styled from 'styled-components';

export const AvatarContainer = styled.div`
    width: 52px;
    height: 52px;
    border-radius: 25px;
    background: radial-gradient(60% 60% at 50% 50%, rgba(106, 236, 58, 0.72) 0%, rgba(106, 236, 58, 0.1872) 100%), linear-gradient(359.53deg, rgba(106, 236, 58, 0.4176) -21.17%, rgba(106, 236, 58, 0) 111.46%);
`;

export const Picture = styled.img`
    
`;

export const InitialsContainer = styled.div`
    width: 100%;
    height: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 25px;
`;