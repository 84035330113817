import PropTypes from 'prop-types';

import * as Routes from 'constants/Routes';
import Logo from 'assets/logo-jf.svg';
import { useLocation, useParams } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { 
    Container, 
    LogoContainer,
    SectionList,
    ListContainer,
    LinkToSection,
    LinkToSectionFloat,
    GroupLogo,
    PoolsLogo,
    ResultsLogo,
    TopLogo,
    AwardsLogo,
    CloseSessionLogo,
    UserLogo,
    ChangeLogo
} from './styles';

const PrivateSidebar = ({ hideSideBar }) => {
    const { pickerType, permalink } = useParams();
    const locationObj = useLocation();
    const location = (locationObj === "/") ? "root" : locationObj.pathname.split('/');
    const { logout } = useUser();

    const handleCloseSession = () => {
        logout();
    }

    return (
        <Container>            
            <LogoContainer to="/">
                <img src={Logo} alt='juanfutbol' />
            </LogoContainer>
            <ListContainer>
                <LinkToSection onClick={hideSideBar} to={Routes.PROFILE} active={Routes.PROFILE.includes(location)} isMobileOnly>
                    <SectionList >
                        <UserLogo active={Routes.HOME.includes(location)} />
                        Mi Perfil
                    </SectionList>
                </LinkToSection>
                {(permalink) &&
                    <LinkToSectionFloat onClick={hideSideBar} to={pickerType}>
                        <SectionList >
                            <ChangeLogo />
                            Cambiar Torneo
                        </SectionList>
                    </LinkToSectionFloat>
                }
                <LinkToSection onClick={hideSideBar} to={(permalink)?`${Routes.POOLS}/${permalink}`:Routes.POOLS} active={Routes.POOLS.includes(location)}>
                    <SectionList >
                        <PoolsLogo active={Routes.POOLS.includes(location)}/>
                        Quinielas
                    </SectionList>
                </LinkToSection>
                <LinkToSection onClick={hideSideBar} to={(permalink)?`${Routes.GROUPS_ID}/${permalink}`:Routes.GROUPS_ID} active={Routes.GROUPS_ID.includes(location)}>
                    <SectionList >
                        <GroupLogo active={Routes.GROUPS_ID.includes(location)}/>
                        Grupos
                    </SectionList>
                </LinkToSection>
                <LinkToSection onClick={hideSideBar} to={(permalink)?`${Routes.RESULTS_ID}/${permalink}`:Routes.RESULTS_ID} active={Routes.RESULTS_ID.includes(location)}>
                    <SectionList >
                        <ResultsLogo active={Routes.RESULTS_ID.includes(location)}/>
                        Mis resultados
                    </SectionList>
                </LinkToSection>
                <LinkToSection onClick={hideSideBar} to={(permalink)?`${Routes.TOP_TEN_ID}/${permalink}`:Routes.TOP_TEN_ID} active={Routes.TOP_TEN_ID.includes(location)}>
                    <SectionList >
                        <TopLogo active={Routes.TOP_TEN_ID.includes(location)}/>
                        Top 10
                    </SectionList>
                </LinkToSection>
                <LinkToSection onClick={hideSideBar} to={(permalink)?`${Routes.AWARDS_ID}/${permalink}`:Routes.AWARDS_ID} active={Routes.AWARDS_ID.includes(location)}>
                    <SectionList >
                        <AwardsLogo active={Routes.AWARDS_ID.includes(location)}/>
                        Premios
                    </SectionList>
                </LinkToSection>
                <LinkToSection onClick={handleCloseSession} to={Routes.HOME} active={Routes.HOME.includes(location)} isMobileOnly>
                    <SectionList >
                        <CloseSessionLogo active={Routes.HOME.includes(location)}/>
                        Cerrar Sesión
                    </SectionList>
                </LinkToSection>
            </ListContainer>
        </Container>
    );
};

PrivateSidebar.propTypes = {
  hideSideBar: PropTypes.func,
};

export default PrivateSidebar;